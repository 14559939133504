import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../../config/messages";
import LocalGridConfig from "../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../config/globalsFunc";
export default class MasterAreaStore {
  List_data = [];
  total = 1;
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1
  dropdown_locality_list = null


  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  onGridChanged = (params) => {
    localStorage.setItem('master_Area', JSON.stringify(params.columnApi.getColumnState()))
  }

  getList = () => {
    return Axios.post(`/api/locality/list`).then(({ data }) => {
      if (data.data) {
        data.data.forEach((item, index) => {
          item.is_active_display =
            item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
        });
      }
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
    })
  }

  EditMasterArea = (data) => {
    return Axios.post(`/api/locality/edit/${data.id}`, { name: data.name }).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  ActiveStatus = (id, status) => {
    Axios.post(`/api/locality/status/${id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
      if (data.status === 200) {
        this.getList()
        vsmNotify.success({ message: data.message })
      }
    }).catch((err) => { return })
  }

  DeleteMasterArea = (id) => {
    return Axios.post(`/api/locality/delete/${id}`).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  AddMasterArea = (e) => {
    return Axios.post(`/api/locality/add`, e).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getLocalityList = () => {
    return Axios.get(`api/locality/dropdownLocality`)
      .then(({ data }) => {
        this.dropdown_locality_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

}

decorate(MasterAreaStore, {
  List_data: observable,
  getList: action,
  setPageSize: action,
  total: observable,
  current_page: observable,
  dropdown_locality_list: observable,
  per_page: observable,
  setupGrid: action,
  ActiveStatus: action,
  createDatasource: action,
  AddMasterArea: action,
  agGrid: observable,
  EditMasterArea: action,
  DeleteMasterArea: action,
  onGridChanged: action,
  getLocalityList: action
})