import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../config/messages";
import LocalGridConfig from "../../config/LocalGridConfig";
import { isActiveStatus } from "../../config/globalsFunc";

export default class RoleStore {
	Read_Data=null
	List_data = [];
	total = 1;
	rolePermissionsData=[];
	per_page = LocalGridConfig.options.paginationPageSize;
	current_page = 1;
    agGrid=  null;

	setupGrid = (params) => {
		this.agGrid = params
	}

	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	getList = () => {
		return Axios.post(`/api/role/list`).then(({ data }) => {
			data.data.forEach((item, index) => {	                   			                    
				item.is_active_display =
					item.is_active === 1 ? isActiveStatus[1] : isActiveStatus[0]
			});			
			this.List_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			return data
		})
	}

	onGridChanged = (params) => {
		localStorage.setItem('role_management', JSON.stringify(params.columnApi.getColumnState()))
	}

	EditRole = (result) => {
		return Axios.post(`/api/role/edit/${result.id}`,result).then(({ data }) => {
			this.getList()			
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	ActiveStatus = (id, status) => {
		Axios.post(`/api/role/status/${id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
			if (data.status === 200) {
				this.getList()
				vsmNotify.success({ message: data.message })
			}
		}).catch((err) => { return })
	}

	DeleteRole = (id) => {		
		return Axios.post(`/api/role/delete/${id}`).then(({ data }) => {
			if (data.status === 200) {
				this.getList()				
			}
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	RoleReadPermission = (payload) => {				
		return Axios.post(`/api/permission/list`, {role_id:payload}).then(({ data }) => {
			this.Read_Data=data
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	RolePermission = () => {
		return Axios.get(`/api/permission/getpermissionlist`).then(({ data }) => { 
			this.rolePermissionsData=data
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	AddRole = (data) => {
		return Axios.post(`/api/role/add`, data).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}
}

decorate(RoleStore, {
    agGrid:observable,
	List_data: observable,
	rolePermissionsData:observable,
	Read_Data:observable,
	ActiveStatus: action,
	getList: action,
	setPageSize: action,
	setupGrid: action,
	onGridChanged: action,
	RolePermission:action,
	AddRole: action,
	EditRole: action,
	DeleteRole: action,
	RoleReadPermission:action
})