import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../../config/messages";
import LocalGridConfig from "../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../config/globalsFunc";

export default class MasterNationalityStore {
	List_data = [];
	total = 1;
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	agGrid=null


	setupGrid = (params) => {
		this.agGrid = params
	}

	// change page size, default page size is GridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	
	getList = () => {
		return Axios.post(`/api/nationality/list`).then(({ data }) => {
			if (data.data) {
				data.data.forEach((item, index) => {	                   			                    
					item.is_active_display =
						item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
				});
			}
            this.List_data = data.data
			this.total = data.total
			this.current_page = data.current_page
		})
	}

	onGridChanged = (params) => {
		localStorage.setItem('master_nationality', JSON.stringify(params.columnApi.getColumnState()))
	}

	EditMasterNationality = (data) => {
		return Axios.post(`/api/nationality/edit/${data.id}`, { "name": data.name }).then(({ data }) => {
			this.getList()			
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	ActiveStatus = (id, status) => {
		Axios.post(`/api/nationality/status/${id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
			if (data.status === 200) {
				this.getList()
				vsmNotify.success({ message: data.message})
			}
		}).catch((err) => { return })
	}

	DeleteMasterNationality = (id) => {
		Axios.post(`/api/nationality/delete/${id}`).then(({ data }) => {
			if (data.status === 200) {
				this.getList()
				vsmNotify.success({ message: 'successfully Deleted' })
			}
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	AddMasterNationality = (name) => {
		return Axios.post(`/api/nationality/add`, name).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}
}

decorate(MasterNationalityStore, {
	agGrid:observable,
	AddMasterNationality: action,
	EditMasterNationality: action,
	DeleteMasterNationality: action,
	List_data: observable,
	ActiveStatus: action,
	getList: action,
	setPageSize: action,
	setupGrid: action,
	onGridChanged: action
})