import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../config/LocalGridConfig";

export default class SystemFormsStore {
  agGrid = null;
  List_data = [];
  total = 1;
  rolePermissionsData = []
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;
  dropdown_form_list = null;
  dropdown_service_user_list = null
  dropdown_assign_manager = null
  editValues = null
  viewValues = null
  dropdown_user_list = null
  dropdown_house_list = null
  dropdown_role_list = null
  dropdown_manager_list = null

  setupGrid = (params) => {
    this.agGrid = params
    const columnFilter = localStorage.getItem("system_form_filter")
    if (this.agGrid && this.agGrid.api && columnFilter) {
      this.agGrid.api.redrawRows();
      this.agGrid.api.setFilterModel(JSON.parse(columnFilter))
    }
  }

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to edit
  setViewValues = (id) => {
    return Axios.get(`/api/systemforms/${id}`).then(({ data }) => {
      // this.viewValues = data.data[0];
      this.viewValues = data.search[0];
    })
  };

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  getList = () => {
    return Axios.post(`/api/systemforms/list`).then(({ data }) => {
      data.data.forEach((item) => {
        item.status_name = item.status === 0 ? "Pending" : item.status === 1 ? "Authorised" : item.status === 2 ? "Rejected" : item.status === 3 ? "Draft" : "N/A"
      })
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page

      return data
    })
  }

  getSystemFormsList = () => {
    return Axios.get(`api/systemforms/dropdownTemplates`)
      .then(({ data }) => {
        this.dropdown_form_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  getServiceUserList = () => {
    return Axios.get(`api/serviceuser/serviceuserlist`)
      .then(({ data }) => {
        this.dropdown_service_user_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  getHouseList = () => {
    return Axios.get(`api/house/gethouselist`)
      .then(({ data }) => {
        this.dropdown_house_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  getRoleList = () => {
    return Axios.get(`api/getRoleList`)
      .then(({ data }) => {
        this.dropdown_role_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  // getAssignManager = (id) => {
  //   return Axios.post(`api/serviceuser/getassignManager/${id}`)
  //     .then(({ data }) => {
  //       this.dropdown_assign_manager = data;
  //       console.log("this.dropdown_assign_manager", this.dropdown_assign_manager)
  //       return data;
  //     })
  //     .catch(response => {
  //       return Promise.reject(response)
  //     })
  // }

  AddSystemForm = (data) => {
    return Axios.post(`/api/systemforms/add`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  EditSystemForm = (id, data) => {
    return Axios.post(`/api/systemforms/edit/${id}`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getUserList = () => {
    return Axios.post(`api/user/userlist`)
      .then(({ data }) => {
        this.dropdown_user_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  getManagerUserList = () => {
    return Axios.post(`api/user/userlist`)
      .then(({ data }) => {
        this.dropdown_manager_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  onGridChanged = (params) => {
    localStorage.setItem('systemform_listing', JSON.stringify(params.columnApi.getColumnState()))
  }

}

decorate(SystemFormsStore, {
  agGrid: observable,
  List_data: observable,
  dropdown_form_list: observable,
  dropdown_service_user_list: observable,
  dropdown_assign_manager: observable,
  dropdown_house_list: observable,
  dropdown_role_list: observable,
  dropdown_manager_list: observable,
  dropdown_user_list: observable,
  editValues: observable,
  viewValues: observable,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  onGridChanged: action,
  JsonToFormData: action,
  getSystemFormsList: action,
  getServiceUserList: action,
  getAssignManager: action,
  getUserList: action,
  getHouseList: action,
  getRoleList: action,
  getManagerUserList: action,
  AddSystemForm: action,
  EditSystemForm: action,
  setEditValues: action,
  setViewValues: action,
})