import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../config/messages";
import LocalGridConfig from "../../config/LocalGridConfig";


export default class SuperVisionLogStore{
  agGrid = null;
  List_data = [];
  total = 1;
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1
 
  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  onGridChanged = (params) => {
    localStorage.setItem('report_supervision', JSON.stringify(params.columnApi.getColumnState()))
  }

  getList = () => {
    return Axios.post(`/api/supervision/list`).then(({ data }) => {
      if (data.data) {
        data.data.forEach((item, index) => {          
          item.review_by_user=item.user ? item.user.first_name + " " + item.user.last_name : null
          item.section = item.category ? item.category.category_name : null
          item.service = item.service ? item.service.first_name+" "+item.service.last_name : null
        });
      }
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
    })
  }

  AddComplain = (e) => {
    return Axios.post(`/api/complaint_log/add`, e).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }


  //Edit Data
  EditSuperVision = (formdata,id) => {
    return Axios.post(`/api/supervision/allupdate/${id}`, formdata)
      .then(({ data }) => {
        this.getList()
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  ActiveStatus = (id, status) => {
    Axios.post(`/api/contact/status/${id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
      if (data.status === 200) {
        this.getList()
        vsmNotify.success({ message: data.message })
      }
    }).catch((err) => { return })
  }

  DeleteComplain = (id) => {
    return Axios.post(`/api/complaint_log/delete/${id}`).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  onFilterChanged=(params)=>{
    if(params.api.rowModel.rowsToDisplay.length === 0){
     this.agGrid.api.showNoRowsOverlay()
    }
    else {
     this.agGrid.api.hideOverlay()
    }
 }

 exportCsv = () => {
  var params = {
    columnKeys: ['id', 'title','outcome', 'description', 'document','title','review_by_user','next_review_date','supervision_date']
};
  this.agGrid.api.exportDataAsCsv(params)
}
}

decorate(SuperVisionLogStore, {
  List_data: observable,
  agGrid: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  getList: action,
  setPageSize: action,
  setupGrid: action,
  createDatasource: action,
  onGridChanged: action,
  AddContact: action,
  EditSuperVision: action,
  AddComplain:action,
  DeleteComplain: action,
  ActiveStatus: action,
  JsonToFormData:action,
  onFilterChanged:action,
  exportCsv:action
})