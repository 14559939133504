import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../../config/messages";
import LocalGridConfig from "../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../config/globalsFunc";
export default class MasterSubCategoriesStore {
  List_data = [];
  dropdown_housedoc_category_list = [];
  total = 1;
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1
  agGrid=null

  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  getList = () => {
    return Axios.get(`/api/category/subcategorylist`).then(({ data }) => {
      if (data) {
        data.forEach((item, index) => {
          item.is_active_display =
            item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
        });
      }
      this.List_data = data
      this.total = data.total
      this.current_page = data.current_page
    })
  }

  getHouseCategoryList = () => {
    return Axios.get(`/api/housedocument/getcategorylist`).then(({ data }) => {
      console.log(data)  
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }  
  

  getCategoryList = () => {
    return Axios.get(`/api/category/getcategorylist`).then(({ data }) => {
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }
  onGridChanged = (params) => {
    localStorage.setItem('master_subcategory', JSON.stringify(params.columnApi.getColumnState()))
  }

  EditMasterSubCatogaries = (e) => {
    return Axios.post(`/api/category/edit/${e.id}`, e).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  ActiveStatus = (id, status) => {
    return Axios.post(`/api/category/status/${id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
      if (data.status === 200) {
        this.getList()
        vsmNotify.success({ message: data.message })
      }
    }).catch((err) => { return })
  }

  DeleteMasterSubCatogaries = (id) => {
    return Axios.post(`/api/category/delete/${id}`).then(({ data }) => {
      this.getList()
    }).catch((err) => { return })
  }

  AddMasterSubCatogaries = (e) => {
    return Axios.post(`/api/category/add`,e).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }
}

decorate(MasterSubCategoriesStore, {
  List_data: observable,
  dropdown_housedoc_category_list:observable,
  agGrid:observable,
  AddMasterSubCatogaries: action,
  EditMasterSubCatogaries: action,
  getCategoryList: action,
  DeleteMasterSubCatogaries: action,
  ActiveStatus: action,
  getList: action,
  setPageSize: action,
  setupGrid: action,
  onGridChanged: action,
  getHouseCategoryList:action,
})