import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../../config/messages";
import LocalGridConfig from "../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../config/globalsFunc";
export default class ManagePostCodeStore {
  List_data = [];
  total = 1;
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1
  TownList = null;
  PostcodeList = [];
  agGrid=null

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  onGridChanged = (params) => {
    localStorage.setItem('master postCode', JSON.stringify(params.columnApi.getColumnState()))
  }

  getList = () => {
    return Axios.post(`/api/postcode/list`).then(({ data }) => {
      if (data.data) {
        data.data.forEach((item) => {
          item.is_active_display = item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
        });
      }
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
    })
  }

  EditMasterPostCode = (data) => {
    return Axios.post(`/api/postcode/edit/${data.id}`, data).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  GetMasterPostTownList = (data) => {
    return Axios.get(`/api/town/gettownlist`).then(({ data }) => {
      // let townList = []
      // data.forEach((item) => {
      //   townList.push({ text: item.name, value: item.id, children: item.postcode });
      // });

      this.TownList = data
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getPostcodeFromTown = (townId) => {
    let postcodeList = []
    if (townId) {
      let townIndex =  this.TownList.findIndex(x => x.id === townId)
      if(townIndex !== -1){
        postcodeList = this.TownList[townIndex]['postcode']
      }
    }
    this.PostcodeList = postcodeList;
    return postcodeList;
  }

  ActiveStatus = (id, status) => {
    Axios.post(`/api/postcode/status/${id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
      if (data.status === 200) {
        this.getList()
        vsmNotify.success({ message: data.message })
      }
    }).catch((err) => { return })
  }

  DeleteMasterPostCode = (id) => {
    return Axios.post(`/api/postcode/delete/${id}`).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  AddMasterPostCode = (e) => {
    return Axios.post(`/api/postcode/add`, e).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }
}

decorate(ManagePostCodeStore, {
  List_data: observable,
  TownList: observable,
  PostcodeList: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  agGrid: observable,
  getList: action,
  setPageSize: action,
  setupGrid: action,
  ActiveStatus: action,
  createDatasource: action,
  AddMasterPostCode: action,
  EditMasterPostCode: action,
  DeleteMasterPostCode: action,
  GetMasterPostTownList: action,
  onGridChanged: action,
  getPostcodeFromTown: action,
})