import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout, Menu } from 'antd';
import { Link } from 'react-router-dom';
import useStore from '../../store';
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactComponent as Dashboard } from '../../assets/img/menu/dashboard.svg';
import { ReactComponent as UserManagement } from '../../assets/img/menu/user_management.svg';
import { ReactComponent as UserDetails } from '../../assets/img/menu/user_details.svg';
import { ReactComponent as HRDetails } from '../../assets/img/menu/hr_detail.svg';
import { ReactComponent as Notes } from '../../assets/img/menu/note_logs.svg';
import { ReactComponent as Qualification } from '../../assets/img/menu/qualification.svg';
import { ReactComponent as PerformanceReview } from '../../assets/img/menu/performance_review.svg';
import { ReactComponent as LeaveTracker } from '../../assets/img/menu/leave_tracker.svg';

import { ReactComponent as RoleManagement } from '../../assets/img/menu/role_management.svg';
import { ReactComponent as HousingManagement } from '../../assets/img/menu/housing_management.svg';
import { ReactComponent as Masters } from '../../assets/img/menu/masters.svg'
import { ReactComponent as AreaLocality } from '../../assets/img/menu/area_location.svg'
import { ReactComponent as Nationality } from '../../assets/img/menu/nationality.svg'
import { ReactComponent as Ethnicity } from '../../assets/img/menu/ethnicity.svg'
import { ReactComponent as Religion } from '../../assets/img/menu/religion.svg'
import { ReactComponent as Category } from '../../assets/img/menu/category.svg'
import { ReactComponent as SubCategory } from '../../assets/img/menu/sub_category.svg'
import { ReactComponent as Town } from '../../assets/img/menu/town.svg'
import { ReactComponent as PostCode } from '../../assets/img/menu/postcode.svg'
import { ReactComponent as Training } from '../../assets/img/menu/training.svg'

import { ReactComponent as Reports } from '../../assets/img/menu/reports.svg'
import { ReactComponent as ComplaintsLogs } from '../../assets/img/menu/complaints_logs.svg'
import { ReactComponent as SafeguardLogs } from '../../assets/img/menu/safeguard_logs.svg'
import { ReactComponent as SupervisionLogs } from '../../assets/img/menu/supervision_logs.svg'
import { ReactComponent as NoteLogs } from '../../assets/img/menu/note_logs.svg'
import { ReactComponent as DailyLogs } from '../../assets/img/menu/daily_logs.svg'
import { ReactComponent as TrainingMatrix } from '../../assets/img/menu/training.svg'

import { ReactComponent as BroadcastMessages } from '../../assets/img/menu/broadcast_messages.svg'
import { ReactComponent as ServiceUsers } from '../../assets/img/menu/service_users.svg'
import { ReactComponent as Contacts } from '../../assets/img/menu/contacts.svg'
import { ReactComponent as Appoinments } from '../../assets/img/menu/appoinments.svg'
import { ReactComponent as Forms } from '../../assets/img/menu/forms.svg'
import { ReactComponent as SystemForms } from '../../assets/img/menu/system_forms.svg'
import { ReactComponent as RiskAssesment } from '../../assets/img/menu/risk_assesment.svg'
import { ReactComponent as HandoverSheet } from '../../assets/img/menu/handover_sheet.svg'
import { ReactComponent as AuditLog } from '../../assets/img/menu/audit_log.svg'
import { ReactComponent as Setting } from '../../assets/img/menu/setting.svg'
import { ReactComponent as MedicationErrorReport } from '../../assets/img/menu/medication_error.svg'
import { ReactComponent as IncidentReport } from '../../assets/img/menu/incident_report.svg'

const { Sider } = Layout;

const AppsSidebarComponent = observer((props) => {
  const { AppsAuthStore } = useStore()
  const location = useLocation()
  const [openMenu, setOpenMenu] = useState([])
  const [openKeys, setOpenKeys] = useState([]);

  let menukey = 1

  const icons = {
    1: <Dashboard className='icon' />,
    2: <UserManagement className='icon' />,
    37: <UserDetails className='icon' />,
    4: <HRDetails className='icon' />,
    5: <Notes className='icon' />,
    6: <Qualification className='icon' />,
    7: <PerformanceReview className='icon' />,
    8: <LeaveTracker className='icon' />,
    9: <RoleManagement className='icon' />,
    10: <HousingManagement className='icon' />,
    11: <Masters className='icon' />,
    12: <AreaLocality className='icon' />,
    13: <Nationality className='icon' />,
    14: <Ethnicity className='icon' />,
    15: <Religion className='icon' />,
    16: <Category className='icon' />,
    17: <SubCategory className='icon' />,
    18: <Town className='icon' />,
    19: <PostCode className='icon' />,
    20: <Training className='icon' />,
    21: <Reports className='icon' />,
    22: <ComplaintsLogs className='icon' />,
    23: <SafeguardLogs className='icon' />,
    24: <SupervisionLogs className='icon' />,
    25: <NoteLogs className='icon' />,
    26: <DailyLogs className='icon' />,
    27: <BroadcastMessages className='icon' />,
    28: <ServiceUsers className='icon' />,
    40: <UserDetails className='icon' />,
    29: <Contacts className='icon' />,
    30: <Appoinments className='icon' />,
    31: <Forms className='icon' />,
    32: <SystemForms className='icon' />,
    33: <RiskAssesment className='icon' />,
    34: <HandoverSheet className='icon' />,
    35: <AuditLog className='icon' />,
    36: <TrainingMatrix className='icon' />,
    44: <Setting className="icon" />,
    45: <MedicationErrorReport className="icon" />,
    46: <IncidentReport className="icon" />
  }



  const AppMenu = (menu_item, open = []) => {
    return menu_item ? Object.keys(menu_item)
      .sort((a, b) => (menu_item[a].sequence_no > menu_item[b].sequence_no) ? 1 : ((menu_item[b].sequence_no > menu_item[a].sequence_no) ? -1 : 0))
      .map((key) => {
        let item = menu_item[key]

        if (item.submenu && (item.id !== 37 && item.id !== 10 && item.id !== 40)) {
          return (
            <>

              <Menu.SubMenu className={item.parent_id === 0 ? 'hasSubMenu' : ''} key={item.title + menukey}
                title={<>
                  {(item.id && icons[item.id]) ? icons[item.id] : <FontAwesomeIcon icon={faCircle} size="xs" />}
                  <span className='menuName'>{item.title}</span>
                </>}>
                {AppMenu(item.submenu, [...open, (item.title + menukey++)])}
              </Menu.SubMenu>
            </>
          )
        } else {

          if (location.pathname.includes(item.path) && openMenu.length <= 0) {
            if (open.length === 0) {
              open.push(item.path)
            }
            setOpenMenu(open)
          }
          if (item.menu_type === 0) {
            return (
              <>
                <Menu.Item key={item.path}
                // icon={<FontAwesomeIcon icon={item.id ? icons[item.id] : faCircle} size="xs" className="mr-10" />}
                >
                  <Link to={item.path}>
                    {(item.id && icons[item.id]) ? icons[item.id] : <FontAwesomeIcon icon={faCircle} size="xs" />}
                    <span className='menuName'>{item.title}</span>
                  </Link>
                  {/* {<Link to={item.path} />} */}
                </Menu.Item>
              </>
            )
          }
          else {
            return null
          }
        }
      }) : null
  }

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (AppsAuthStore.menuHasSubmenu.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <>
      <Sider trigger={null} collapsible collapsed={props.collapsed} className={`sideMenu ${props.collapsed ? "collapsed" : ""}`}>

        {props.collapsed
          ? <div className="logo"><Link to="/dashboard">CMS</Link></div>
          : <div className="logo"><Link to="/dashboard">CMS</Link></div>
        }

        <Menu theme="dark" mode="inline" defaultOpenKeys={openMenu} selectedKeys={location.pathname} onOpenChange={onOpenChange}>
          {AppMenu(AppsAuthStore.apps_menu)}
        </Menu>
      </Sider>
    </>
  );
})

export default AppsSidebarComponent;