import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { RiskAssessmentStatusList } from "../../config/globalsFunc";
import LocalGridConfig from "../../config/LocalGridConfig";

export default class AuditStore {
  agGrid = null;
  List_data = [];
  total = 1;
  rolePermissionsData = []
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;
  editValues = null
  deleteValues = null
  dropdown_house_list = null

  setupGrid = (params) => {
    this.agGrid = params
  }

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };


  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };


  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  getList = () => {
    return Axios.get(`/api/audit/list`).then(({ data }) => {
      data.data.forEach((item) => {
        // if (item.created_by) {
        //   item.createdBy = item.created_by?.first_name + " " + item.created_by?.last_name
        // }
        item.status_name = RiskAssessmentStatusList[item.status]
      })
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
      return data
    })
  }

  AddAudit = (data) => {
    return Axios.post(`/api/audit/add`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  EditAudit = (id, data) => {
    return Axios.post(`/api/audit/edit/${id}`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  DeleteAudit = (id, data) => {
    return Axios.post(`/api/audit/delete/${id}`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getHouseList = () => {
    return Axios.get(`api/house/gethouselist`)
      .then(({ data }) => {
        this.dropdown_house_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }


  onGridChanged = (params) => {
    localStorage.setItem('audit_list', JSON.stringify(params.columnApi.getColumnState()))
  }
}

decorate(AuditStore, {
  agGrid: observable,
  List_data: observable,
  editValues: observable,
  deleteValues: observable,
  dropdown_house_list: observable,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  AddAudit: action,
  EditAudit: action,
  DeleteAudit: action,
  onGridChanged: action,
  JsonToFormData: action,
  setEditValues: action,
  setDeleteValues: action,
  getHouseList: action
})