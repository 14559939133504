import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../../config/messages";
import ServerSideGridConfig from "../../../config/GridConfig";
import { Messages } from "../../../config/messages/messages";

export default class NoteDetailStore {
  List_data = [];
  total = 1;
  agGrid = null;
  rolePermissionsData = []
  per_page = ServerSideGridConfig.options.paginationPageSize
  current_page = 1;

  setUserId = (params) => {
    this.user_id = params;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;
    let datasource = this.createDatasource(ServerSideGridConfig.options);
    api.setServerSideDatasource(datasource);
  };

  // Filter function for no record found message
  changeFilterAndSort = (params) => {
    var final_filter = params.filterModel;
    var final_sort = params.sortModel;
    return { final_filter, final_sort };
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        var filter_data = this.changeFilterAndSort(params.request);
        var payload = {
          entity_type: "user_note",
          filter_data: filter_data.final_filter,
          sort_data: filter_data.final_sort,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
              (params.request.endRow - params.request.startRow)
          ),
        };
        this.getList(this.user_id, payload).then((data) => {
          if (data.total === 0) {
            this.agGrid.api.showNoRowsOverlay();
          } else {
            this.agGrid.api.hideOverlay();
          }
          params.successCallback(data.data, data.total);
          var allColumnIds = [];
          if (this.agGrid && this.agGrid.columnApi && data.total) {
            this.agGrid.columnApi.getAllColumns().forEach(function (column) {
              allColumnIds.push(column.col_id);
            });
          }
        })
      },
    };
  };

  // Filter function for no record found message
  onFilterChangedList = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(parseInt(page));
    }
  };

  getList = (currentUserDetailId, payload) => {
    return Axios.post(`/api/user/edit/note/list/${currentUserDetailId}`, payload).then(({ data }) => {
      data.data.forEach((item) => {
        item.last_updated_by = item.staff_id ? item.staff_first_name + " " + item.staff_last_name : ''
        item.section = item.category_name ? item.category_name : ''
      })
      if (data?.length) {
        let startPage = (data.current_page - 1) * data.per_page;
        data.data.map((item, index) => {
          item.sr_no = startPage + index + 1;
          return null;
        });
      }
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
      return data
    }).catch(() => {
      vsmNotify.success({ message: Messages.Something_Went_Wrong });
      // history.push('/housing')
    });
  }

  AddNoteDetails = (result, data, id) => {
    return Axios.post(`/api/user/edit/note/add/${data.id}`, result).then(({ data }) => {
      this.setupGrid(this.agGrid);
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }


  EditNoteDetails = (formData, result) => {
    return Axios.post(`/api/user/edit/note/edit/${result.id}/${result.actionId}`, formData).then(({ data }) => {
      this.setupGrid(this.agGrid);
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  DeleteNoteDetails = (result) => {
    return Axios.post(`/api/user/edit/note/delete/${result.actionId}`).then(({ data }) => {
      this.setupGrid(this.agGrid);
      return data
    }).catch(({ response: { data } }) => {
      vsmNotify.error({ message: data })
    })

  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  getCategoryList = () => {
    return Axios.post(`/api/user/edit/note/getcategorylist`,{type:"staff_user_notes"}).then(({ data }) => {
      return data
    })
  }

  onGridChanged = (params) => {
    localStorage.setItem('details_notes', JSON.stringify(params.columnApi.getColumnState()))
  }

}

decorate(NoteDetailStore, {
  List_data: observable,
  ActiveStatus: action,
  setupGrid: action,
  agGrid: observable,
  setPageSize: action,
  getList: action,
  onGridChanged: action,
  getCategoryList: action,
  AddNoteDetails: action,
  EditNoteDetails: action,
  DeleteNoteDetails: action,
  JsonToFormData: action
})