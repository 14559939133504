import Axios from "axios";
import { action, decorate, observable } from "mobx";
import ServerSideGridConfig from "../../config/GridConfig";
import { vsmNotify } from "../../config/messages";
import { Messages } from "../../config/messages/messages";

export default class DailyLogTabStore {
  agGrid = null;
  List_data = [];
  total = 1;
  rolePermissionsData = [];
  per_page = ServerSideGridConfig.options.paginationPageSize;
  current_page = 1;
  editValues = null;
  deleteValues = null;
  dropdown_category_list = null;
  category_parent_id = null;
  user_id = null;

  getList = (id, payload) => {
    return Axios.post(`/api/user/edit/note/list/${id}`, payload).then(
      ({ data }) => {
        data.data.forEach((item) => {
          if (item.staff_id) {
            item.last_updated_by = item.staff_first_name + " " + item.staff_last_name;
          }
          item.section = item.category_name ? item.category_name : ''
        });
        if (data?.length) {
          let startPage = (data.current_page - 1) * data.per_page;
          data.data.map((item, index) => {
            item.sr_no = startPage + index + 1;
            return null;
          });
        }
        this.List_data = data.data ? data.data : null;
        this.total = data.total;
        // this.setPageSize(data.per_page);
        this.current_page = data.current_page;
        return data;
      }
    ).catch(() => {
      vsmNotify.success({ message: Messages.Something_Went_Wrong });
      // history.push('/housing')
    });
  };

  setUserId = (params) => {
    this.user_id = params;
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;
      // this.agGrid.api.setFilterModal({
      //   notes_date: {
      //     dateFrom: moment().format("YYY-MM-DD"),
      //     dateTo: moment().format("YYYY-MM-DD"),
      //     type: "inRange",
      //     filterType: "date",
      //   },
      //   // updated_at: {
      //   //   dateFrom: moment().format("YYY-MM-DD"),
      //   //   dateTo: moment().format("YYYY-MM-DD"),
      //   //   type: "inRange",
      //   //   filterType: " date",
      //   // },
      // });
    let datasource = this.createDatasource(ServerSideGridConfig.options);
    api.setServerSideDatasource(datasource);
  };

  // Filter function for no record found message
  changeFilterAndSort = (params) => {
    var final_filter = params.filterModel;
    var final_sort = params.sortModel;
    return { final_filter, final_sort };
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        var filter_data = this.changeFilterAndSort(params.request);
        var payload = {
          entity_type: "service_user_note",
          filter_data: filter_data.final_filter,
          sort_data: filter_data.final_sort,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
              (params.request.endRow - params.request.startRow)
          ),
        };
        this.getList(this.user_id, payload).then((data) => {
          if (data.total === 0) {
            this.agGrid.api.showNoRowsOverlay();
          } else {
            this.agGrid.api.hideOverlay();
          }
          params.successCallback(data.data, data.total);
          var allColumnIds = [];
          if (this.agGrid && this.agGrid.columnApi && data.total) {
            this.agGrid.columnApi.getAllColumns().forEach(function (column) {
              allColumnIds.push(column.col_id);
            });
          }
        });
      },
    };
  };

  // Filter function for no record found message
  onFilterChangedList = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(parseInt(page));
    }
  };

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (![document].includes(key)) {
        formData.append(
          key,
          data[key] !== undefined && data[key] !== null ? data[key] : ""
        );
      }
    });
    return formData;
  };

  AddDailyLog = (data, parentId) => {
    return Axios.post(`/api/user/edit/note/add/${parentId}`, data)
      .then(({ data }) => {
        this.setupGrid(this.agGrid);
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            if (name === "both") {
              notify = data.errors[name][0];
            } else {
              errors.push({ name, errors: data.errors[name] });
            }
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  EditDailyLog = (data, parentId, childId) => {
    return Axios.post(`/api/user/edit/note/edit/${parentId}/${childId}`, data)
      .then(({ data }) => {
        this.setupGrid(this.agGrid);
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            if (name === "both") {
              notify = data.errors[name][0];
            } else {
              errors.push({ name, errors: data.errors[name] });
            }
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  DeleteDailyLog = (data, parentId, childId) => {
    return Axios.post(`/api/user/edit/note/delete/${childId}`, data)
      .then(({ data }) => {
        this.setupGrid(this.agGrid);
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            if (name === "both") {
              notify = data.errors[name][0];
            } else {
              errors.push({ name, errors: data.errors[name] });
            }
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  getCategoryList = () => {
    return Axios.post(`api/user/edit/note/getcategorylist`, {
      type: "service_user_notes",
    })
      .then(({ data }) => {
        this.dropdown_category_list = data.subcategory;
        this.category_parent_id = data.parent_id;
        return data;
      })
      .catch((response) => {
        return Promise.reject(response);
      });
  };

  AddMasterSubCatogaries = (e) => {
    return Axios.post(`/api/category/add`, e)
      .then(({ data }) => {
        this.getCategoryList();
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            if (name === "both") {
              notify = data.errors[name][0];
            } else {
              errors.push({ name, errors: data.errors[name] });
            }
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  onGridChanged = (params) => {
    localStorage.setItem(
      "service_user_daily_log_tab",
      JSON.stringify(params.columnApi.getColumnState())
    );
  };
}

decorate(DailyLogTabStore, {
  agGrid: observable,
  List_data: observable,
  editValues: observable,
  deleteValues: observable,
  dropdown_category_list: observable,
  category_parent_id: observable,
  user_id: observable,
  setUserId: action,
  ActiveStatus: action,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  onGridChanged: action,
  AddDailyLog: action,
  EditDailyLog: action,
  JsonToFormData: action,
  setEditValues: action,
  setDeleteValues: action,
  DeleteContactDetail: action,
  getCategoryList: action,
  AddMasterSubCatogaries: action,
});
