import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../config/LocalGridConfig";
import { IsChecked, IsMandatory,IsQualificationStatus } from "../../../config/globalsFunc";
import { vsmNotify } from "../../../config/messages";

export default class QualificationStore {
  List_data = [];
  total = 1;
  agGrid = null
  rolePermissionsData = []
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;

  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  getList = (currentUserDetailId) => {
    return Axios.post(`/api/user/edit/qualification/list/${currentUserDetailId}`).then(({ data }) => {

      data.data.forEach((item) => {
        item.is_checked_status = item.is_checked === 1 ? IsChecked[1] : IsChecked[0]
        item.is_mandatory_status = item.is_mandatory ? IsMandatory[1] : IsMandatory[2]
        
        item.is_qualification_status = item.status === 2 ? IsQualificationStatus[2] : item.status === 1 ? IsQualificationStatus[1] : IsQualificationStatus[0]
      })
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
      return data
    })
  }

  AddTrainigDetails = (result, data, id) => {
    return Axios.post(`/api/user/edit/qualification/add/${data.id}`, result).then(({ data }) => {
      this.getList(id)
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  //Update Status
  updateStatus = (formdata) => {
    return Axios.post(`/api/user/edit/qualification/status/${formdata?.id}`, { status: formdata.status })
      .then(({ data }) => {
        this.getList(formdata?.user_id)
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  EditTrainingDetails = (formData, result) => {
    return Axios.post(`/api/user/edit/qualification/edit/${result.id}/${result.actionId}`, formData).then(({ data }) => {
      this.getList(result.id)
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }


  getCategoryList = (Id) => {
    return Axios.get(`/api/getManageTrainingList`).then(({ data }) => {
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getTrainingDropDownList = (id) => {
    return Axios.post(`/api/user/edit/qualification/traininglist/${id}`).then((data) => {
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }
  onGridChanged = (params) => {
    localStorage.setItem('qualification_training', JSON.stringify(params.columnApi.getColumnState()))
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }
  deleteTraining = (props) => {
    return Axios.post(`/api/user/edit/qualification/delete/${props.actionId}`).then((data) => {
      this.getList(props.id)
      return data
    }).catch(() => {
      vsmNotify.error({ message: 'something went wrong' })
    })
  }

}

decorate(QualificationStore, {
  List_data: observable,
  agGrid: observable,
  ActiveStatus: action,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  onGridChanged: action,
  getCategoryList: action,
  AddTrainigDetails: action,
  EditHrDetails: action,
  getTrainingDropDownList: action,
  EditTrainingDetails: action,
  JsonToFormData: action,
  deleteTraining: action,
  updateStatus: action
})