import { Alert, Button, Col, Form, Popover, Row, Spin } from "antd";
import React, { useState } from "react";
// import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import InputComponent from "../../components/InputComponent";
import useStore from "../../store";
import { vsmAuth, vsmNotify } from "../../config/messages";
import { ReactComponent as EmailIcon } from "../../assets/img/email.svg";
import { ReactComponent as PasswordIcon } from "../../assets/img/lock-gray.svg";
import CoreProspectsLogo from "../../assets/img/CoreProspects_logo.svg";
// import { ReactComponent as CoreProspectsLogo1 } from "../../assets/img/CoreProspects_logo.svg";
import BackgroundBg from "../../assets/img/console.jpg";
//import { initializeApp } from "firebase/app";
// //import { getMessaging, onMessage, getToken } from "firebase/messaging";
// import { getTokenInit } from "./../../firebase";

const Login = (props) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [commonError, setCommonError] = useState(null);
  const [tooltip, setTooltip] = useState(false);
  const [IconHoverEffect, setIconHoverEffect] = useState({
    EmailIcon: false,
    passwordIcon: false,
  });

  // const [messaging, setMessaging] = useState();
  // const [fcmToken, setFcmToken] = useState();

  const { AppsAuthStore } = useStore();

  const sendData = (data) => {
    setLoading(true);
    data.fcm_token = localStorage.getItem("fcmToken");
    AppsAuthStore.doAppsLogin(data)
      .then(() => {
        vsmNotify.success({
          message: vsmAuth.success,
        });
      })
      .catch((errors) => {
        if (errors && errors.notify) {
          //This  is for password field
          setCommonError(errors.notify);
          setLoading(false);
        }

        if (errors && errors.errors) {
          //This is for email field
          var err_data = errors.errors[0].errors[0];
          setCommonError(err_data);
          setLoading(false);
        }
      });
  };

  // password criteria tooltip
  const passwordTooltip = (
    <div>
      <div>at least 1 numeric character</div>
      <div>at least 1 special character</div>
      <div>at least 1 uppercase letter</div>
      <div>at least 8 character</div>
    </div>
  );

  // handle password tool tip visiblility
  const handleChangePassword = (e) => {
    form
      .validateFields(["password"])
      .then(() => {
        setTooltip(false);
      })
      .catch(() => {
        setTooltip(true);
      });
  };

  // async function setFirebaseApp() {
  //   try {
  //     const firebaseConfig = {
  //       apiKey: process.env.REACT_APP_FIREBASE_MESSAGING_API_KEY,
  //       authDomain: process.env.REACT_APP_FIREBASE_MESSAGING_AUTH_DOMAIN,
  //       projectId: process.env.REACT_APP_FIREBASE_MESSAGING_PROJECT_ID,
  //       storageBucket: process.env.REACT_APP_FIREBASE_MESSAGING_STORAGE_BUCKET,
  //       messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  //       appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
  //       measurementId: process.env.REACT_APP_FIREBASE_MESSAGING_MEASUREMENT_ID,
  //     };

  //     initializeApp(firebaseConfig);
  //     setMessaging(getMessaging());
  //     console.log("messaging", messaging);
  //     if (window.isSecureContext) {
  //       if ("serviceWorker" in navigator) {
  //         navigator.serviceWorker
  //           .register("firebase-messaging-sw.js")
  //           .then(() => {
  //             // console.log('test Registration successful, scope is:', registration.scope);
  //           })
  //           .catch(() => {
  //             // console.log('test Service worker registration failed, error:', err);
  //           });
  //       }
  //     }
  //   } catch (err) {
  //     console.error("err", err);
  //   }
  // }

  // function startFCM() {
  //   getToken(messaging, {
  //     vapidKey: process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY,
  //   })
  //     .then((currentToken) => {
  //       console.log("enter");
  //       if (currentToken) {
  //         setFcmToken(currentToken);
  //       }
  //     })
  //     .catch(() => {
  //       //
  //     });
  // }

  // messaging &&
  //   onMessage(messaging, function (payload) {
  //     // console.log('Received foreground message ', JSON.stringify(payload));
  //     const title = payload.notification.title;
  //     const options = {
  //       body: payload.notification.body,
  //       icon: payload.notification.icon,
  //     };
  //     new Notification(title, options).addEventListener("click", (event) => {
  //       event.preventDefault(); // prevent the browser from focusing the Notification's tab
  //       //window.open(payload.data.click_action, '_blank');
  //       // redirectNotification(payload.data.notification_id, history, t);
  //     });
  //   });

  // useEffect(() => {
  //   setFirebaseApp();
  //   messaging && startFCM();
  // }, [messaging]);

  return (
    <>
      <div
        className="login__wrapper"
        style={{ backgroundImage: `url('${BackgroundBg}')` }}
      >
        <div className="Login__left">
          <div className="Login_left_contain">
            <h3 className="Login_left_top_heading">Nice to see you again</h3>
            <h1 className="Login_left_welcome_heading">Welcome back</h1>
            <h4 className="title">Our Vision</h4>
            <p className="Login_left_content">
              The hopes and dreams of our service users come to fruition, their
              disabilities no longer a barrier to them having fulfilling and
              active lives. It is our role to inspire change and enable service
              users to manage their own lives as independently as possible and
              develop and use their own abilities.
            </p>
            <p className="Login_left_content">
              The future we envision is one where those with disabilities are
              treated as equals, and they and their families are able to access
              the support they need – when they need it. A society where social
              care, supported living and those benefitting from it are truly
              recognised and valued.
            </p>
          </div>
        </div>
        <div className="Login__right">
          <div>
            <div className="Login_right_logo">
              <img src={CoreProspectsLogo} alt="Core Prospects" />
              {/* <CoreProspectsLogo1 /> */}
            </div>
            <div className="Login_InputComponent_Container">
              <div className="Login_InputComponents">
                <Form
                  layout="vertical"
                  form={form}
                  onFinish={sendData}
                  labelCol={{ span: 24 }}
                >
                  <h1 className="Login_text_header">Log In</h1>
                  {commonError && (
                    <Alert
                      style={{ marginBottom: 15 }}
                      message={commonError}
                      type="error"
                      showIcon
                    />
                  )}

                  <Row>
                    <Col xs={{ span: 24 }} className="pb-20">
                      <InputComponent
                        className="loginInput"
                        name="email"
                        hasFeedback
                        placeholder="Email Id"
                        rules={vsmAuth.validation.email}
                        // style={{ height: '60px', outerWidth: '0px', borderRadius: '5px', fontSize: '22px' }}
                        onFocus={() =>
                          setIconHoverEffect({
                            ...IconHoverEffect,
                            EmailIcon: true,
                          })
                        }
                        onBlur={() =>
                          setIconHoverEffect({
                            ...IconHoverEffect,
                            EmailIcon: false,
                          })
                        }
                        prefix={
                          <EmailIcon
                            className={
                              IconHoverEffect.EmailIcon ? "blue" : "gray"
                            }
                          />
                        }
                      />
                    </Col>
                    <Col xs={{ span: 24 }} className="pb-20">
                      <Popover
                        placement="topRight"
                        content={passwordTooltip}
                        visible={tooltip}
                      >
                        <InputComponent
                          className="loginInput"
                          name="password"
                          type="password"
                          hasFeedback
                          placeholder="Enter the password"
                          rules={vsmAuth.validation.password}
                          // style={{ height: '60px', outerWidth: '0px', borderRadius: '5px', fontSize: '22px' }}
                          onChange={handleChangePassword}
                          onFocus={() =>
                            setIconHoverEffect({
                              ...IconHoverEffect,
                              passwordIcon: true,
                            })
                          }
                          onBlur={
                            (() =>
                              setIconHoverEffect({
                                ...IconHoverEffect,
                                passwordIcon: false,
                              }),
                            () => setTooltip(false))
                          }
                          prefix={<PasswordIcon className={"passIcon"} />}
                        />
                      </Popover>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 12 }}
                      className="login_forget_password_text text-left mt-15"
                    >
                      <Link to="/forget-password">Forgot Password?</Link>
                    </Col>
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 12 }}
                      className="text-right  mt-10"
                    >
                      <Button htmlType="submit" type="primary">
                        Log In {loading ? <Spin /> : null}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Login;
