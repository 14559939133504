import { action, decorate, observable } from "mobx"
import Axios from "axios"
import { vsmNotify } from "../config/messages"

export default class DashboardStore {
    isDashHouse=false
    isTrainingDue=false
    isStaffBirthDate=false
    isUpcomingPerfomanceReviews=false
    isUpcomingMeditation=false
    isUpcomingHr=false
    issuperVision=false
    dashboardData = null
    credebtorType = null
    HouseAgGrid=null
    SuperVisionAgGrid=null
    UpcomingAgGrid=null
    TrainingDueGrid=null
    MeditationAgGrid=null
    HouseList=[]
    superVisionData=[]
    upcomingHrDetails=[]
    TrainingDueList=[]
    UpComingMeditation=[]
    staffBirthDate=[]
    upcomingPerfomanceList=[]
    staffBirthAgGrid=null
    UpcomingPerformanceAgGrid=null
   
    
    HouseSetUpGrid = (params) => {
        this.HouseAgGrid = params
    }

    SuperVisionSetUpGrid = (params) => {
        this.SuperVisionAgGrid = params
    }

    UpComingHrSetupGrid = (params) => {
        this.UpcomingAgGrid=params
    }

    TrainingDueSetUpGrid = (params) => {
        this.TrainingDueGrid=params
    }

    ComingMeditationSetUpGrid = (params) => {
        this.MeditationAgGrid=params
    }

    StaffBirthSetUpGrid = (params) => {
        this.staffBirthAgGrid=params
    }

    UpcomingPerformanceSetUpGrid = (params) => {
        this.UpcomingPerformanceAgGrid = params
    }

    onchangePerfomanceReview = (params) => {
        localStorage.setItem('upcoming_perfomance_review', JSON.stringify(params.columnApi.getColumnState()))
    }

    onchangeStaffBirthDate = (params) => {
        localStorage.setItem('Staff_birthdate_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    onChangeUpComingHr = (params) => {        
        localStorage.setItem('UpComingHr_grid', JSON.stringify(params.columnApi.getColumnState()))
    }
    onChangeHouseGrid = (params) => {
        localStorage.setItem('dashHouse_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    onChangeSuperVisionGrid = (params) => {        
        localStorage.setItem('dashSuperVision_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    onChangeTrainingDue = (params) => {
        localStorage.setItem('DashTrainingDue_grid', JSON.stringify(params.columnApi.getColumnState()))
    }

    onChangeMeditationAssessment = (params) => {
        localStorage.setItem('dash_meditation_grid', JSON.stringify(params.columnApi.getColumnState()))
    }
     

    getDashboardData = () => {        
        return Axios.get('get/dashboard').then(({ data }) => {
            this.dashboardData = data.data
        }).catch((err) => {
            vsmNotify.error({ message: err.message })
        })
    }

    getDashHouse = () => {
        return Axios.get('api/dashboard/housing').then(({data})=>{          
            this.HouseList=data
            this.isDashHouse=true
            return data
        }).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
    }
    
    getDashSuperVision = () => {
        return Axios.get('api/dashboard/supervisions_due').then(({data})=>{            
            this.superVisionData=data
            this.issuperVision=true  
        }).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
    }

    getComingHrDetails = () => {
        return Axios.get('api/dashboard/upcoming_hr_expiry').then(({data})=>{         
            this.upcomingHrList=data
            this.isUpcomingHr=true
        }).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
    }

    getTrainingDue = () => {
        return Axios.get('api/dashboard/training_due').then(({data})=>{            
            this.TrainingDueList=data
            this.isTrainingDue=true
            return data
        }).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
    }

    getUpComingMeditation = () => {
        return Axios.get('api/dashboard/upcoming_medication_assessments').then(({data})=>{            
            this.UpComingMeditation=data
            this.isUpcomingMeditation=data
            return data
        }).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
    }
    
    getstaffBirthdate = () => {
        return Axios.get('api/dashboard/staff_birthdate').then(({data})=>{            
            this.staffBirthDate=data
            this.isStaffBirthDate=true
            return data
        }).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
    }

    UpcomingPerfomanceReviews = () => {
        return Axios.get('api/dashboard/upcoming_performance_review').then(({data})=>{            
            this.upcomingPerfomanceList=data         
        }).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
    }    


}

decorate(DashboardStore, {
    isTrainingDue:observable,
    isDashHouse:observable,
    isStaffBirthDate:observable,
    isUpcomingPerfomanceReviews:observable,
    isUpcomingHr:observable,
    isUpcomingMeditation:observable,
    issuperVision:observable,
    HouseAgGrid:observable,
    TrainingDueGrid:observable,
    UpcomingAgGrid:observable,
    SuperVisionAgGrid:observable,
    MeditationAgGrid:observable,
    HouseList:observable,
    dashboardData: observable,
    TrainingDueList:observable,
    UpComingMeditation:observable,
    staffBirthDate:observable,
    staffBirthAgGrid:observable,
    upcomingHrList:observable,
    upcomingPerfomanceList:observable,
    UpcomingPerformanceAgGrid:observable,
    HouseSetUpGrid:action,
    onChangeMeditationAssessment:action,        
    getComingHrDetails:action,
    onChangeUpComingHr:action,
    UpComingHrSetupGrid:action,
    onChangeSuperVisionGrid:action,
    getDashSuperVision:action,
    SuperVisionSetUpGrid:action,
    onChangeHouseGrid:action,
    getDashboardData: action,
    getTrainingDue:action,
    onChangeTrainingDue:action,
    TrainingDueSetUpGrid:action,
    getUpComingMeditation:action,
    staffBirthdate:action,
    onchangeStaffBirthDate:action,
    StaffBirthSetUpGrid:action,
    UpcomingPerfomanceReviews:action,
    onchangePerfomanceReview:action,
    UpcomingPerformanceSetUpGrid:action
})