import { createContext, useContext } from "react"
import DashboardStore from "./DashboardStore"
import AppsAuthStore from "./AppsAuthStore"
import MasterAreaStore from "./master/masterAreaLocalityStore/masterAreaStore"
import MasterNationalityStore from "./master/masterNationalLocalityStore/masterNationalStore"
import MasterEthnicityStore from "./master/masterEthnicityStore/masterEthinicityStore"
import RoleStore from "./RoleManagentStore/RoleManagent"
import ReligionStore from "./master/masterReligionStore/masterReligionStore"
import MasterCatogariesStore from "./master/masterCatogaries/masterCatogariesStore"
import MasterSubCategoriesStore from './master/subcategoriesStore/subCategariesStore'
import ManageTownStore from "./master/manageTownStore/manageTownStore"
import ManagePostCodeStore from "./master/managePostStore/managePostStore"
import MasterTrainingStore from "./master/masterTraining/materTraining"
import ManageProfileStore from "./userProfile/UserProfile"
import ManageChangePassword from "./userProfile/ChangePassword"
import UserManagementStore from "./UserManagementStore/UserManagement"
import ContactsStore from "./ContactsStore/ContactsStore"
import HrDetailStore from "./UserManagementStore/HrDetailStore/HrDetailStore"
import NoteDetailStore from "./UserManagementStore/NotesDetail/NoteDetailStore"
import QualificationStore from "./UserManagementStore/QualificationStore.js/QualificationStore"
import PerformanceStore from "./UserManagementStore/PerformanceStore/PerformanceStore"
import LeaveTrackerStore from "./UserManagementStore/LeaveTrackerStore/LeaveTracker"
import HousingStore from "./HousingStore/HousingStore"
import DocumentsStore from "./HousingStore/DocumentsStore"
import HousingNoteStore from "./HousingStore/HousingNoteStore"
import AppoinmentStore from "./AppoinmentStore/AppoinmentStore"
import ServiceUsersStore from "./ServiceUsersStore/ServiceUsersStore"
import ComplainLogStore from "./ComplainLogStore/ComplainLogStore"
import SafeguardStore from "./reports/safeguardStore"
import ReportDailyStore from './reports/dailyLog'
import ContactInfoTabStore from "./ServiceUsersStore/ContactInfoTabStore"
import AuditLogStore from "./AuditLogStore/AuditLogStore"
import DailyLogTabStore from "./ServiceUsersStore/DailyLogTabStore"
import DocumentsTabStore from "./ServiceUsersStore/DocumentsTabStore"
import NotesDailyLog from "./reports/notesLog"
import SuperVisionLogStore from "./reports/supervisionLog"
import TrainingMatrixStore from "./reports/TrainingMatrix"
import BroadcastMessageStore from "./BroadcastMessageStore/BroadcastMessageStore"
import HandoverSheetStore from "./Forms/HandoverSheetStore"
import RiskAssessmentStore from "./Forms/RiskAssessmentStore"
import SystemFormsStore from "./Forms/SystemFormsStore"
import SettingStore from "./Forms/SettingStore"
import AuditStore from "./AuditStore/AuditStore"
import MedicationErrorReportStore from "./Forms/MedicationErrorReportStore"
import IncidentReportStore from "./Forms/IncidentReportStore"
import AuditFormsStore from "./AuditFormsStore/AuditFormsStore"

const AppContext = createContext({
  AppsAuthStore: new AppsAuthStore(),
  DashboardStore: new DashboardStore(),
  MasterAreaStore: new MasterAreaStore(),
  MasterNationalityStore: new MasterNationalityStore(),
  MasterEthnicityStore: new MasterEthnicityStore(),
  MasterCatogariesStore: new MasterCatogariesStore(),
  MasterSubCategariesStore: new MasterSubCategoriesStore(),
  RoleStore: new RoleStore(),
  ReligionStore: new ReligionStore(),
  ManageTownStore: new ManageTownStore(),
  ManagePostCodeStore: new ManagePostCodeStore(),
  MasterTrainingStore: new MasterTrainingStore(),
  ManageProfileStore: new ManageProfileStore(),
  ManageChangePassword: new ManageChangePassword(),
  UserManagement: new UserManagementStore(),
  ContactsStore: new ContactsStore(),
  HrDetailStore: new HrDetailStore(),
  NoteDetailStore: new NoteDetailStore(),
  QualificationStore: new QualificationStore(),
  PerformanceStore: new PerformanceStore(),
  LeaveTrackerStore: new LeaveTrackerStore(),
  HousingStore: new HousingStore(),
  DocumentsStore: new DocumentsStore(),
  HousingNoteStore: new HousingNoteStore(),
  AppoinmentStore: new AppoinmentStore(),
  ServiceUsersStore: new ServiceUsersStore(),
  ComplainLogStore: new ComplainLogStore(),
  SafeguardStore: new SafeguardStore(),
  ContactInfoTabStore: new ContactInfoTabStore(),
  AuditLogStore: new AuditLogStore(),
  DailyLogTabStore: new DailyLogTabStore(),
  ReportDailyStore: new ReportDailyStore(),
  NotesDailyLog: new NotesDailyLog(),
  SuperVisionLogStore: new SuperVisionLogStore(),
  DocumentsTabStore: new DocumentsTabStore(),
  TrainingMatrixStore: new TrainingMatrixStore(),
  BroadcastMessageStore: new BroadcastMessageStore(),
  HandoverSheetStore: new HandoverSheetStore(),
  RiskAssessmentStore: new RiskAssessmentStore(),
  SystemFormsStore: new SystemFormsStore(),
  SettingStore: new SettingStore(),
  AuditStore: new AuditStore(),
  MedicationErrorReportStore: new MedicationErrorReportStore(),
  IncidentReportStore: new IncidentReportStore(),
  AuditFormsStore: new AuditFormsStore()
})

const useStore = () => useContext(AppContext)
export default useStore
