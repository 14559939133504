import moment from 'moment'
export const lazyRetry = function (componentImport) {

  return new Promise((resolve, reject) => {

    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );

    componentImport().then((component) => {
      window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
      resolve(component);
    }).catch((error) => {
      if (!hasRefreshed) { // not been refreshed yet
        window.sessionStorage.setItem('retry-lazy-refreshed', 'true');
        return window.location.reload(); // refresh the page
      }
      reject(error);
    });
  });
};
export const ComplainStatus = {
  1: "Progress",
  2: "Internal - Remain on File",
  3: "Pending",
  4: "Referred to CQC",
  5: "Resolved",
  6: "Social Service Involvement",
  7: "UnResolved"
}

export const FormComplainStatus = [
  { value: 1, text: "Progress" },
  { value: 2, text: "Internal - Remain on File" },
  { value: 3, text: "Pending" },
  { value: 4, text: "Referred to CQC" },
  { value: 5, text: "Resolved" },
  { value: 6, text: "Social Service Involvement" },
  { value: 7, text: "UnResolved" }
]

export const isActiveStatus = {
  1: "Active",
  0: "Deactive"
}
export const IsMandatory = {
  1: "Mandatory",
  2: "Service Specific"
}

export const IsFrequency = {
  1: "Once",
  2: "Annually"
}

export const IsChecked = {
  1: 'Yes',
  0: 'No'
}

export const RelationshipArray = [
  { value: 0, text: 'Brother' },
  { value: 1, text: 'Sister' },
  { value: 2, text: 'Father' },
  { value: 3, text: 'Mother' },
  { value: 4, text: 'Friend' },
  { value: 5, text: 'Grandparent' },
  { value: 6, text: 'Guardian' },
  { value: 7, text: 'Partner' },
  { value: 8, text: 'Spouse' },
  { value: 9, text: 'Other' },
]

export const MartitalStatus = [
  { value: 0, text: 'Married' },
  { value: 1, text: 'Single' },
  { value: 2, text: 'Widowed' },
  { value: 3, text: 'Divorced' },
]

export const SexValues = [
  { value: 0, text: 'Male' },
  { value: 1, text: 'Female' },
  { value: 2, text: 'Transgender' }
]
export const LeaveCategory = [
  { text: 'Holiday leaves', value: 1 },
  { text: 'Sickness leaves', value: 2 },
  { text: 'Other Leaves', value: 3 },
]

export const IsQualificationStatus = {
  0: "Pending",
  1: "In Progress",
  2: "Completed"
}

export const ServiceUserStatusList = {
  1: 'Active',
  2: 'Holiday',
  3: 'Hospital',
  4: 'New',
  5: 'Respite',
  6: 'InActive'
}

export const RiskAssessmentStatusList = {
  0: 'Pending',
  1: 'Authorised',
  2: 'Rejected',
  3: 'Draft'
}

/* 
Formatting data for Display
form.setFieldsValue({
  dob : dateFormat(value,false,true),   // moment object for datepicker input
  dob_withformat : dateFormat(value,false), // Ag Grid Date Display
})

Passing data to Backend
data.dob = data.dob ? dateFormat(data.dob) : null;
 */

export const FrontDateFormat = "DD-MM-YYYY"
export const FrontDateTimeFormat = "DD-MM-YYYY HH:mm"
export const BackendDateFormat = "YYYY-MM-DD"

export const DateFormat = (date = new Date(), isPost = true, withoutFormat = false) => {
  if (isPost) {
    return moment(date).format(BackendDateFormat)
  }
  else if (withoutFormat) {
    return moment(date)
  }
  else {
    return moment(date).format(FrontDateFormat)
  }
}