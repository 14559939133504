import Axios from "axios";
import { action, decorate } from "mobx";


export default class ManageChangePassword {        
    ChangePasswordApi = (e) => {                
        return Axios.post(`/api/user/change/password`,e).then(({ data }) => {                                                   
            return data
            //return this.user_data = data.data
            }).catch(({ response: { data } }) => {
                var errors = []
                var notify = null
                if (data.errors) {
                    Object.keys(data.errors).forEach(name => {
                        if (name === 'both') {
                            notify = data.errors[name][0]
                        } else {
                            errors.push({ name, errors: data.errors[name] })
                        }
                    })
                }
                return Promise.reject({ errors, notify })
            })
    }      
}

decorate(ManageChangePassword,{    
    ChangePasswordApi : action,       
})