import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../config/messages";
import LocalGridConfig from "../../config/LocalGridConfig";
import { isActiveStatus } from "../../config/globalsFunc";
export default class ContactsStore {
  agGrid = null;
  List_data = [];
  total = 1;
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1


  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  onGridChanged = (params) => {
    localStorage.setItem('contact_list', JSON.stringify(params.columnApi.getColumnState()))
  }

  getList = () => {
    return Axios.post(`/api/contact/list`).then(({ data }) => {
      if (data.data) {
        data.data.forEach((item, index) => {
          item.is_active_display = item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
          item.fullname = item.first_name + " " + item.last_name
        });
      }
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
    })
  }

  AddContact = (e) => {
    return Axios.post(`/api/contact/add`, e).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }


  //Edit Data
  EditContact = (id, formdata) => {
    return Axios.post(`/api/contact/edit/${id}`, formdata)
      .then(({ data }) => {
        this.getList()
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  ActiveStatus = (id, status) => {
    Axios.post(`/api/contact/status/${id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
      if (data.status === 200) {
        this.getList()
        vsmNotify.success({ message: data.message })
      }
    }).catch((err) => { return })
  }

  DeleteContact = (id) => {
    return Axios.post(`/api/contact/delete/${id}`).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }


}

decorate(ContactsStore, {
  List_data: observable,
  agGrid: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  getList: action,
  setPageSize: action,
  setupGrid: action,
  createDatasource: action,
  onGridChanged: action,
  AddContact: action,
  EditContact: action,
  DeleteContact: action,
  ActiveStatus: action,
})