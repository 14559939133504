import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../config/messages";
import LocalGridConfig from "../../config/LocalGridConfig";
import { ComplainStatus } from "../../config/globalsFunc";

export default class ComplainLogStore {
  agGrid = null;
  List_data = [];
  total = 1;
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;
  dropdown_house_list = null

  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  onGridChanged = (params) => {
    localStorage.setItem('complain_log', JSON.stringify(params.columnApi.getColumnState()))
  }

  getList = () => {
    return Axios.post(`/api/complaint_log/list`).then(({ data }) => {
      if (data.data) {
        data.data.forEach((item, index) => {
          item.is_active_display = ComplainStatus[Number(item.complain_status)]
          item.fullname = item.first_name + " " + item.last_name
        });
      }
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
    })
  }

  AddComplain = (e) => {
    return Axios.post(`/api/complaint_log/add`, e).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }


  //Edit Data
  EditComplain = (formdata, id) => {
    return Axios.post(`/api/complaint_log/edit/${id}`, formdata)
      .then(({ data }) => {
        this.getList()
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  ActiveStatus = (id, status) => {
    Axios.post(`/api/contact/status/${id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
      if (data.status === 200) {
        this.getList()
        vsmNotify.success({ message: data.message })
      }
    }).catch((err) => { return })
  }

  DeleteComplain = (id) => {
    return Axios.post(`/api/complaint_log/delete/${id}`).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getHouseList = () => {
    return Axios.get(`api/house/gethouselist`)
      .then(({ data }) => {
        this.dropdown_house_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  onFilterChanged = (params) => {
    console.log("Params is", params)
    if (params.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay()
    }
    else {
      this.agGrid.api.hideOverlay()
    }
  }


  toDataURL(src, callback) {
    var image = new Image();
    image.crossOrigin = 'Anonymous';

    image.onload = function () {
      var canvas = document.createElement('canvas');
      var context = canvas.getContext('2d');
      canvas.height = this.naturalHeight;
      canvas.width = this.naturalWidth;
      context.drawImage(this, 0, 0);
      var dataURL = canvas.toDataURL('image/jpeg');
      callback(dataURL);
    };
    image.src = src;
  }




  exportCsv = (Image) => {

    var params = {
      columnKeys: ['id', 'complain_date', 'complain_made_by', 'complain_status', 'date_resolved', 'handled_by', 'person', 'result']
    };
    this.agGrid.api.exportDataAsCsv(params)
  }

}

decorate(ComplainLogStore, {
  List_data: observable,
  agGrid: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  dropdown_house_list: observable,
  getList: action,
  setPageSize: action,
  setupGrid: action,
  createDatasource: action,
  onGridChanged: action,
  AddContact: action,
  EditComplain: action,
  AddComplain: action,
  DeleteComplain: action,
  ActiveStatus: action,
  JsonToFormData: action,
  onFilterChanged: action,
  exportCsv: action,
  getHouseList: action
})