import React, { Suspense, useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CoreprosAppRouter from './utils/OriginatorAppRouter'
import useStore from './store'
import { observer } from 'mobx-react'
import { message, Result, Spin } from 'antd'
import Login from './pages/authpage/Login'
import AppsLayoutComponent from './components/originator/AppsLayoutComponent'
import { getTokenInit } from './firebase';


const App = observer(() => {
  const {
    AppsAuthStore: { setAppsAxiosInterceptors, prospects_open_login, prospects_user, doLogOut }
  } = useStore()
  const [isOnline, setOnlineStatus] = useState()

  useEffect(() => {
    setAppsAxiosInterceptors()
  }, [setAppsAxiosInterceptors])

  useEffect(() => {
    if (isOnline === 'online') {
      message.success('You are online again.');
    }
  }, [isOnline])


  useEffect(() => {
    getTokenInit();
  }, [prospects_user]);


  window.addEventListener('online', () => setOnlineStatus('online'));
  window.addEventListener('offline', () => setOnlineStatus('offline'));

  document.addEventListener("mousemove", () => {
    localStorage.setItem('lastActvity', new Date())
  });
  document.addEventListener("click", () => {
    localStorage.setItem('lastActvity', new Date())
  });
  document.addEventListener("keydown", () => {
    localStorage.setItem('lastActvity', new Date())
  });

  let timeInterval = setInterval(() => {
    let lastAcivity = localStorage.getItem('lastActvity')
    var diffMs = Math.abs(new Date(lastAcivity) - new Date()); // milliseconds between now & last activity
    var seconds = Math.floor((diffMs / 1000));
    var minute = Math.floor((seconds / 60));
    // console.log("lastAcivity", lastAcivity)
    // console.log(seconds + ' sec and ' + minute + ' min since last activity')
    if (minute > 5) {
      // console.log('No activity from last 5 minutes... Logging Out')
      clearInterval(timeInterval)
      doLogOut()
    }

  }, 360000)



  return (
    <BrowserRouter>
      <Switch>
        <Route path="/">
          {prospects_open_login
            ? <Login />
            : <AppsLayoutComponent>
              <Suspense fallback={<div className="fullscreen__spinner"><Spin size="large" /></div>}>
                <CoreprosAppRouter />
              </Suspense>
            </AppsLayoutComponent>
          }
        </Route>
      </Switch>
      {(isOnline === 'offline') && (
        <div className="offline_section">
          <Result
            status="500"
            title="No Internet!"
            subTitle="Please check your internet connectivity."
          />
        </div>
      )}
    </BrowserRouter>
  )
})

export default App
