import Axios from "axios";
import { action, decorate, observable } from "mobx";



export default class ManageProfileStore {
  user_data = null;
  postcode = null;
  allTownList = null;
  dropdownRoleList = null;
  rolePermisionList = null;
  userDataWithPremission = null;
  EditFormData = null;
  RoleUpdatedOrNot = false;
  dropdown_house_list = null;


  Read_User_Profile = (id) => {
    return Axios.get(`/api/edit/profile/${id}`).then(({ data }) => {
      return this.user_data = data.data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }


  Read_User_Details = (id) => {
    return Axios.get(`/api/user/${Number(id)}`).then(({ data }) => {
      return this.user_data = data.data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  send_user_data = (data, id) => {
    return Axios.post(`/api/user/edit/profile/${id}`, data).then(({ data }) => {
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }


  getTownPostList = () => {
    return Axios.get(`/api/town/gettownlist`).then(({ data }) => {
      return data
    }
    )
  }


  getTownList = () => {
    return Axios.get(`/api/town/gettownlist`).then(({ data }) => {
      return this.allTownList = data.filter(_x => _x.postcode.length !== 0);
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }



  getEthicityList = () => {
    return Axios.get(`/api/ethnicity/getethnicitylist`).then(({ data }) => {
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getNationalityList = () => {
    return Axios.get(`/api/nationality/getnationalitylist`).then(({ data }) => {
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getReligionList = () => {
    return Axios.get(`/api/religion/dropdownlist`).then(({ data }) => {
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getHouseList = () => {
    return Axios.get(`/api/house/gethouselist`).then(({ data }) => {
      this.dropdown_house_list = data;
      return data;
    })
      .catch(response => {
        return Promise.reject(response)
      })
  }


  getRolesList = () => {
    return Axios.get('/api/getRoleList').then(({ data }) => {
      this.dropdownRoleList = data;
      return data
    })
  }

  setUserDataWithPremission = (data) => {
    this.userDataWithPremission = data;
    return data;
  }
  setEditFormData = (data) => {
    this.EditFormData = data;
    return data;
  }
  setRoleUpdatedOrNot = (data) => {
    this.RoleUpdatedOrNot = data;

  }

  RoleReadPermission = (payload) => {
    return Axios.post(`/api/permission/list`, payload).then(({ data }) => {
      this.rolePermisionList = data.data
      return data.data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }
}

decorate(ManageProfileStore, {
  user_data: observable,
  postcode: observable,
  allTownList: observable,
  dropdownRoleList: observable,
  rolePermisionList: observable,
  userDataWithPremission: observable,
  EditFormData: observable,
  RoleUpdatedOrNot: observable,
  dropdown_house_list: observable,
  Read_User_Details: action,
  send_user_data: action,
  getTownList: action,
  getTownPostList: action,
  getReligionList: action,
  getEthicityList: action,
  Read_User_Profile: action,
  getRolesList: action,
  getNationalityList: action,
  getHouseList: action,
  RoleReadPermission: action,
  setUserDataWithPremission: action,
  setRoleUpdatedOrNot: action,
})