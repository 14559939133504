import React, { useState, useEffect, useCallback } from 'react';
import { Badge, Dropdown, Layout, Menu, Popover } from 'antd'; //Card
import { observer } from 'mobx-react';
import { MenuUnfoldOutlined, MenuFoldOutlined, } from '@ant-design/icons'; //BellOutlined, SettingOutlined, SearchOutlined 
import { faBell, faAngleDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useStore from '../../store';
import { Link, useHistory } from 'react-router-dom';
import { Messages } from '../../config/messages/messages';
import { vsmNotify } from '../../config/messages';
import userProfile from '../../assets/img/user_profile.png';
// import { DateFormat } from '../../config/globalsFunc';
import moment from 'moment';
const { Header } = Layout;

const AppsHeaderComponent = observer((props) => {
  const { AppsAuthStore } = useStore()
  let history = useHistory()

  const [notificationModal, setNotificationModal] = useState(false)

  // call logout function
  const Logout = () => {
    AppsAuthStore.dashboardValue = null
    AppsAuthStore.doLogOut()
    vsmNotify.success({ message: Messages.LOGOUT_SUCCESS })
    history.replace('/')
  }

  const userMenu = (
    <Menu className="">
      <Menu.Item key="profile">
        <Link to="/profile">View Profile</Link>
      </Menu.Item>
      <Menu.Item key="change-password">
        <Link to="/change-password">Change Password</Link>
      </Menu.Item>
      <Menu.Item key="logout" onClick={Logout}>
        Logout
      </Menu.Item>
    </Menu>
  )

  const content = (
    <div className="notificationWrap">
      <ul>
        {
          AppsAuthStore.notification_list && AppsAuthStore.notification_list.slice(0, 5).map((item) => {
            return (
              <li>
                <a href={item.url}>
                  <p>{item.content}</p>
                  <span>{moment(item.created_at).format("DD-MM-YYYY hh:mm")}</span>
                </a>
              </li>
            )
          })
        }
        {
          AppsAuthStore.notification_list && AppsAuthStore.notification_list.length > 5 &&
          <li className="seeMoreLink">
            <Link to='/notification' onClick={() => { setNotificationModal(false) }}>See More</Link>
          </li>
        }
      </ul>
    </div>
  );

  const noContent = (
    <div className="notificationWrap noContent">
      <p>No Notification</p>
      <ul>
        <li className="seeMoreLink">
          <Link to='/notification' onClick={() => { setNotificationModal(false) }}>See More</Link>
        </li>
      </ul>
    </div>
  )

  const handleVisibleChange = (visible) => {
    setNotificationModal(visible)
  };

  // useEffect(() => {

  //   // window.addEventListener('storage', handleLocalStorageEvent);
  // }, [AppsAuthStore.notification_count])



  const handleLocalStorageEvent = useCallback(e => {
    AppsAuthStore.notification_count = localStorage.getItem("notificationCount")
  }, [AppsAuthStore.notification_count]);

  useEffect(() => {
    window.addEventListener("storage", handleLocalStorageEvent);
    return () => {
      window.removeEventListener("storage", handleLocalStorageEvent);
    };
  }, [handleLocalStorageEvent]);


  return (
    <>
      <Header className="site-layout-background site-header">
        <div className="menuToggle">
          {(props.collapsed) ? (
            <MenuUnfoldOutlined className="trigger" onClick={() => { props.setCollapsed(false) }} />
          ) : (
            <MenuFoldOutlined className="trigger" onClick={() => { props.setCollapsed(true) }} />
          )}
        </div>
        <div className="rightContent">
          <ul>
            <li>
              {
                localStorage.getItem("notificationCount") > 0 ?
                  <Popover
                    placement="bottom"
                    content={content}
                    trigger="click"
                    overlayClassName="notificationPopover"
                    visible={notificationModal}
                    onVisibleChange={handleVisibleChange}
                  >
                    <Badge size="small" onClick={() => { AppsAuthStore.getNotificationList(); }} count={localStorage.getItem("notificationCount")} overflowCount={99}>
                      <FontAwesomeIcon icon={faBell} size="xs" className='bell' />
                    </Badge>
                  </Popover>
                  :
                  <Popover
                    placement="bottom"
                    content={noContent}
                    trigger="click"
                    overlayClassName="notificationPopover"
                    visible={notificationModal}
                    onVisibleChange={handleVisibleChange}
                  >
                    <Badge color='#F80C0C' size="small">
                      <FontAwesomeIcon icon={faBell} size="xs" className='bell' />
                    </Badge>
                  </Popover>
              }
            </li>
            <li>
              <Dropdown overlay={userMenu} placement="bottomRight" overlayClassName="accountMenu" trigger={['hover']}>
                <div className='accountBtn'>
                  <span className='avatar'>
                    <img src={AppsAuthStore?.prospects_user && AppsAuthStore?.prospects_user?.profile_url ? `${AppsAuthStore?.server_document_url}/${AppsAuthStore?.prospects_user?.profile_url}` : userProfile} alt={AppsAuthStore?.prospects_user?.first_name + " " + AppsAuthStore?.prospects_user?.last_name} />
                  </span>
                  {AppsAuthStore.prospects_user
                    &&
                    <span className='userName'>{AppsAuthStore?.prospects_user?.first_name} {AppsAuthStore?.prospects_user?.last_name}</span>
                  }
                  <FontAwesomeIcon icon={faAngleDown} size="xs" className='dropDown' />
                </div>
              </Dropdown>
            </li>
          </ul>
        </div>
      </Header>
    </>
  )
})
export default AppsHeaderComponent;