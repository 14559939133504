import Axios from "axios";
import { action, decorate, observable } from "mobx";
import moment from "moment";
import LocalGridConfig from "../../../config/LocalGridConfig";
import { vsmNotify } from "../../../config/messages";

export default class LeaveTrackerStore {
	List_data = [];
	agGrid=null;
	List_details_data=[]
	total = 1;
	rolePermissionsData=[]
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1;
    HolidayEntitlementNo = null;
    LeaveTrackerList = null;


  setupGrid = (params) => {
		this.agGrid = params
	}

	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	getList = () => {		
		return Axios.post(`/api/user/list`).then(({ data }) => {			
      if(data.data){
        data.data.map((item, index)=>{
          item.fullname = item.first_name + " " + item.last_name;
          return null
        })
      }
			this.List_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			return data
		})
	}

	getLeaveListDetails = (result) => {		
		return Axios.post(`/api/user/edit/leavetracker/edit/leavelist/${result.id}/${result.actionId}`).then(({data})=>{						
			if(data)
			{
			  this.List_details_data=data.data
			  return data.data
			}			
			})}
	
	
	getTrackerList = (ID) => {
		return Axios.post(`/api/user/edit/leavetracker/list/${ID}`).then(({data})=>{			
			data.data.forEach((item)=>{
				item.start_year = item.start_year ? moment(item.start_year).format('YYYY-MM-DD') : ''
				item.end_year = item.end_year ? moment(item.end_year).format('YYYY-MM-DD') : ''
			})
      this.LeaveTrackerList= data.data;
			return data.data
		})
	} 

	AddLeave = (userId,actionId,payload) => {
		return Axios.post(`/api/user/edit/leavetracker/edit/add/${userId}/${actionId}`,payload).then(({data})=>{
			this.getLeaveListDetails({id:userId,actionId:actionId})
			return data			
		}).catch(({ response: { data } }) => {                          
            var errors = []
            var notify = null
            if (data.errors) {
                Object.keys(data.errors).forEach(name => {
                    if (name === 'both') {
                        notify = data.errors[name][0]
                    } else {
                        errors.push({ name, errors: data.errors[name] })
                    }
                })
            }                           
            return Promise.reject({ errors, notify })
        })
	}

	EditLeave = (userId,actionId,payload) => {
		return Axios.post(`/api/user/edit/leavetracker/edit/edit/${userId}/${actionId}/${payload.id}`,payload).then(({data})=>{
			this.getLeaveListDetails({id:userId,actionId:actionId})
			return data			
		}).catch(({ response: { data } }) => {                          
            var errors = []
            var notify = null
            if (data.errors) {
                Object.keys(data.errors).forEach(name => {
                    if (name === 'both') {
                        notify = data.errors[name][0]
                    } else {
                        errors.push({ name, errors: data.errors[name] })
                    }
                })
            }                           
            return Promise.reject({ errors, notify })
        })
	}

	DeleteLeave = (id,actionId,userId) => {
		return Axios.post(`/api/user/edit/leavetracker/edit/delete/${id}`).then(({data})=>{                            
			this.getLeaveListDetails({id:userId,actionId:actionId})                     
			return data
	}).catch(({ response: { data } }) => {            
		vsmNotify.error({message:'something went wrong'})		
		//return Promise.reject({ errors, notify })
	})
	}
	
	onGridChanged = (params) => {
		localStorage.setItem('child-leavetracker-list', JSON.stringify(params.columnApi.getColumnState()))
	}

	onGridChangedleave = (params) => {
		localStorage.setItem('main_leave_tracker_list', JSON.stringify(params.columnApi.getColumnState()))		
	}


  UpdateHolidayEntitlement = (ID,userID, payload) => {
		return Axios.post(`/api/user/edit/leavetracker/edit/HolidayEntitlement/${ID}`,payload).then(({data})=>{
			this.getTrackerList(userID);
			return data			
		}).catch(({ response: { data } }) => {                          
            var errors = []
            var notify = null
            if (data.errors) {
                Object.keys(data.errors).forEach(name => {
                    if (name === 'both') {
                        notify = data.errors[name][0]
                    } else {
                        errors.push({ name, errors: data.errors[name] })
                    }
                })
            }                           
            return Promise.reject({ errors, notify })
        })
	}

}

decorate(LeaveTrackerStore, {
	agGrid:observable,
	List_data: observable,
	List_details_data:observable,
	LeaveTrackerList:observable,
	ActiveStatus: action,
	getLeaveListDetails:action,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onGridChanged: action,
	getTrackerList:action,
	onGridChangedleave:action,
	AddLeave:action,
	EditLeave:action,
	DeleteLeave:action,
	DeletUpdateHolidayEntitlementeLeave:action,
})