import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../config/LocalGridConfig";
import { vsmNotify } from "../../../config/messages";

export default class PerformanceStore {
  List_data = [];
  total = 1;
  agGrid = null;
  rolePermissionsData = []
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;

  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  getList = (currentUserDetailId) => {
    return Axios.post(`/api/user/edit/supervision/list/${currentUserDetailId}`).then(({ data }) => {
      if (data.data) {
        data.data.forEach((item) => {
          item.reviewed_by_id = item.user && item.user.username
        })
      }
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
      return data
    })
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  AddPerformanceDetails = (result, data, id) => {
    return Axios.post(`/api/user/edit/supervision/add/${data.id}`, result).then(({ data }) => {
      this.getList(id)
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }


  EditPerformanceDetails = (formData, result) => {
    return Axios.post(`/api/user/edit/supervision/edit/${result.id}/${result.actionId}`, formData).then(({ data }) => {
      this.getList(result.id)
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  deletePerformance = (result) => {
    return Axios.post(`/api/user/edit/supervision/delete/${result.actionId}`).then(({ data }) => {
      this.getList(result.id)
      return data
    }).catch(() => {
      vsmNotify.error({ message: 'something went wrong' })
    })
  }

  onGridChanged = (params) => {
    localStorage.setItem('performance_userdetails', JSON.stringify(params.columnApi.getColumnState()))
  }

  getUserList = () => {
    return Axios.post('/api/user/list').then(({ data }) => {
      return data.data
    }).catch(() => {
      vsmNotify.error({ message: 'something went wrong' })
    })
  }

  getReviewUserList = () => {
    return Axios.post('/api/user/review_by_list').then(({ data }) => {
      return data.data
    }).catch(() => {
      vsmNotify.error({ message: 'something went wrong' })
    })
  }
}

decorate(PerformanceStore, {
  List_data: observable,
  ActiveStatus: action,
  setupGrid: action,
  agGrid: observable,
  setPageSize: action,
  getList: action,
  onGridChanged: action,
  AddPerformanceDetails: action,
  EditPerformanceDetails: action,
  deletePerformance: action,
  getUserList: action,
  JsonToFormData: action
})