import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../config/LocalGridConfig";

export default class MedicationErrorReportStore {
  agGrid = null;
  List_data = [];
  total = 1;
  rolePermissionsData = []
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;
  dropdown_form_list = null
  dropdown_category_list = null

  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  getList = () => {
    return Axios.get(`/api/medicationerror/list`).then(({ data }) => {
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
      return data
    })
  }


  onGridChanged = (params) => {
    localStorage.setItem('medication_error_report_list', JSON.stringify(params.columnApi.getColumnState()))
  }
}

decorate(MedicationErrorReportStore, {
  agGrid: observable,
  List_data: observable,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  onGridChanged: action,
  JsonToFormData: action,
})