import { notification } from "antd"
import { Patterns } from "./messages/patterns"
import moment from 'moment'


const vsmAuth = {
  success: "Logged in successful",
  forgot_success: "An email successfully sent to user with link to set new password.",
  reset_success: "Password has been updated successfully.",
  validation: {
    email: [
      { required: true, message: "Email Address cannot be empty." },
      { pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Invalid email address, please enter the valid email address" },
    ],
    password: [
      { required: true, message: "Password cannot be empty." },
      { pattern: /(?=.*[0-9])(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])(?=.{8,})/, message: "It should contain at least an uppercase letter, a lowercase letter, a number, and a special character. ( !@#$%^&=+ )" },
    ],
    confirmpassword: [
      { required: true, message: "Confirm Password cannot be empty." },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject('New Password and Confirm Password does not match.');
        },
      }),
    ]
  }
}

const vsmCommon = {
  noRecord: "No Records Found.",
}

const vsmNotify = {
  success: (data) => {
    notification.success({ placement: "bottomRight", duration: 3, ...data })
  },
  error: (data) => {
    notification.error({ placement: "bottomRight", duration: 3, ...data })
  }
}
const vsmUserDetails = {
  add: "Successfully created",
  edit: "Successfully updated",
  validation: {
    house_id: [
      { required: true, message: "Please select house" },
    ],
  }
}

const vsmContact = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
  validation: {
    firstName: [
      { required: true, message: "First Name is required" },
      { min: 3, message: "The first name must be at least 3 characters." },
      { max: 20, message: "The first name must not be greater than 20 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The first name cannot begin with a space.' }
    ],
    lastName: [
      { required: true, message: "Last Name is required" },
      { min: 3, message: "The last name must be at least 3 characters." },
      { max: 20, message: "The last name must not be greater than 20 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The last name cannot begin with a space.' }
    ],
    phone: [
      { required: true, message: "Phone is required" },
      {
        // eslint-disable-next-line
        pattern: /^(\+\d{1,3}\s\d|\(\+\d{1,3}\)\s\d|\d)[\d\s\(\)\-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\(\+\d{1,3}\)\s\d|\d)[\d\s\(\)\-]{7,12}$/,
        message: "Invalid phone characters, allow 0-9, dash, space and bracket"
      },
      { min: 8, message: "The phone must be at least 8 characters." },
      { max: 12, message: "The phone must not be greater than 12 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The phone number cannot begin with a space.' }
    ],
    other: [
      { max: 500, message: "It must not be greater than 500 character."}
    ]
  }
}

const vsmHousing = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
  validation: {
    name: [
      { required: true, message: "Name is required." },
      { min: 3, message: "The name must be at least 3 characters." },
      { max: 50, message: "The name must not be greater than 50 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The  name cannot begin with a space.' }
    ]
  }
}

const vsmServiceUsers = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
  validation: {
    title: [
      { required: true, message: "Title is required." },
      { min: 2, message: "The title must be at least 2 characters." },
      { max: 20, message: "The title must not be greater than 20 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The title cannot begin with a space.' }
    ],
    house_id: [
      { required: true, message: "Please select house" },
    ],
    assign_manager: [
      { required: true, message: "Please select Manager" },
    ],
    next_of_kin: [
      { min: 3, message: "The title must be at least 3 characters." },
      { max: 20, message: "The title must not be greater than 20 characters." },
    ],
    emergency_phone: [
      { min: 8, message: "The phone number should be minimum 8 characters" },
      { max: 15, message: "The phone number should be maximum 15 characters" },
      { pattern: Patterns.PHONE, message: "Invalid phone characters, allow 0-9, dash, space and bracket" },
    ],
    address_line_1: [
      { required: true, message: "Address is required." },
      // { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'Address cannot begin with a space.' }
    ],
    town: [
      { required: true, message: "Town is required." },
    ],
    postcode: [
      { required: true, message: "Postcode is required." },
    ],
    allergies: [
      { min: 3, message: "The allergies number should be minimum 3 characters" },
      { max: 50, message: "The allergies number should be maximum 50 characters" },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'Allergies cannot begin with a space.' }
    ],
    user_id: [
      { required: true, message: "Please select user" },
    ],
    // status: [
    //   { required: true, message: "Please select status" },
    // ],
    sex: [
      { required: true, message: "Please select sex" },
    ],
    // marital_status: [
    //   { required: true, message: "Please select marital status" },
    // ],
    social_worker: [
      { required: true, message: "Social Worker/Care is required." },
      { min: 3, message: "The social worker/care must be at least 3 characters." },
      { max: 50, message: "The social worker/care must not be greater than 50 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The social worker/care cannot begin with a space.' }
    ],
    date_of_birth: [
      { required: true, message: "Please select date of birth" },
    ],
    gp: [
      { min: 3, message: "The gp number should be minimum 3 characters" },
      { max: 50, message: "The gp number should be maximum 50 characters" },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'GP cannot begin with a space.' }
    ],
    gp_phone: [
      { min: 8, message: "The phone must be at least 8 characters." },
      { max: 15, message: "The phone must not be greater than 15 characters." },
      {
        pattern: /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/,
        message: "Invalid phone characters, allow 0-9, dash, space and bracket"
      }
    ],
    doctor: [
      { min: 3, message: "The doctor must be at least 3 characters." },
      { max: 50, message: "The doctor must not be greater than 50 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The doctor cannot begin with a space.' }
    ],
    psychologist: [
      { min: 3, message: "The psychologist must be at least 3 characters." },
      { max: 50, message: "The psychologist must not be greater than 50 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The psychologist cannot begin with a space.' }
    ],
    psychiatrists: [
      { min: 3, message: "The psychiatrist must be at least 3 characters." },
      { max: 50, message: "The psychiatrist must not be greater than 50 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The psychiatrist cannot begin with a space.' }
    ],
    package_hours: [
      { max: 5, message: "The package hours must not be greater than 5 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The package hours cannot begin with a space.' },
      { pattern: Patterns.NUMBER, message: 'The package hours must be nummber only.' }
    ],
    n_i_number: [
      { min: 3, message: "The NI number must be at least 3 characters." },
      { max: 20, message: "The NI number must not be greater than 20 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The NI number cannot begin with a space.' },
      { pattern: Patterns.NUMBER, message: 'The NI number must be nummber only.' }
    ],
    n_h_s_number: [
      { min: 3, message: "The NHS number must be at least 3 characters." },
      { max: 20, message: "The NHS number must not be greater than 20 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The NHS number cannot begin with a space.' },
      { pattern: Patterns.NUMBER, message: 'The NHS number must be nummber only.' }
    ],
  }
}

const vsmServiceUserTab = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
  validation: {
    title: [
      { min: 2, message: "The title must be at least 2 characters." },
      { max: 20, message: "The title must not be greater than 20 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The title cannot begin with a space.' }
    ],
    first_name: [
      { required: true, message: "First Name is required" },
      { min: 3, message: "The first name must be at least 3 characters." },
      { max: 20, message: "The first name must not be greater than 20 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The first name cannot begin with a space.' }
    ],
    last_name: [
      { min: 3, message: "The last name must be at least 3 characters." },
      { max: 20, message: "The last name must not be greater than 20 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The last name cannot begin with a space.' }
    ],
    phone: [
      { required: true, message: "Phone is required" },
      { min: 8, message: "The phone must be at least 8 characters." },
      { max: 15, message: "The phone must not be greater than 15" },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The phone cannot begin with a space.' },
      {
        pattern: /^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12},(\se|e)xt.(\s\d{2,5}|\d{2,5})$|^(\+\d{1,3}\s\d|\d)[\d\s-]{7,12}$/,
        message: "Invalid phone characters, allow 0-9, dash, space and bracket"
      },
    ],
    mobile: [
      { min: 8, message: "The mobile must be at least 8 characters." },
      { max: 12, message: "The mobile must not be greater than 12" },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The mobile cannot begin with a space.' },
      {
        pattern: /^[0-9]*$/,
        message: "Invalid phone characters, allow 0-9, dash, space and bracket"
      },
    ],
    email: [
      { min: 8, message: "The email must be at least 8 characters." },
      { max: 50, message: "The email must not be greater than 50" },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The mobile cannot begin with a space.' },
      { pattern: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i, message: "Invalid email address, please enter the valid email address" },
    ],
    relationship: [
      { min: 3, message: "The relationship must be at least 3 characters." },
      { max: 50, message: "The relationship must not be greater than 50" },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The mobile cannot begin with a space.' },
    ]
  }
}


const complainLog = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
  validation: {
    complain_date: [
      { required: true, message: "Complaint Date is required" }
    ],
    person: [
      { required: true, message: 'Person is required' },
      { min: 3, message: "The person must be at least 3 characters." },
      { max: 50, message: "The person must not be greater than 50 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The  person cannot begin with a space.' }
    ],
    complain_made_by: [
      { required: true, message: 'Complaint is required' },
      { min: 3, message: "The complaint must be at least 3 characters." },
      { max: 50, message: "The complaint must not be greater than 50 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The complaint cannot begin with a space.' }
    ],
    detail: [
      { required: true, message: 'Details is required' },
    ],
    complaint_status: [
      { required: true, message: "Please select Status. " }
    ],
    handled_by: [
      { required: true, message: 'Handled by is required' },
      { min: 3, message: "The handled by must be at least 3 characters" },
      { max: 50, message: "The handled by must not be greater than 50 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The handled cannot begin with a space.' }
    ],
    house_id: [
      { required: true, message: "Please select House" }
    ],
  }
}

const vsmServiceDailyLogTab = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
  validation: {
    title: [
      { required: true, message: "Title is required" },
      { min: 3, message: "The title must be at least 3 characters." },
      { max: 50, message: "The title must not be greater than 50 characters." },
      { pattern: Patterns.TEXT_WITH_ONE_SPACE, message: 'The title cannot begin with a space.' }
    ],
    date: [
      { required: true, message: "Please select date. " }
    ],
    section: [
      { required: true, message: "Section is required" }
    ],
    notes: [
      { required: true, message: "Notes is required" }
    ],
    timetaken: [
      { required: true, message: "Time Taken is required" }
    ],
  }
}

const vsmServiceDocumentTab = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
  validation: {
    document: [
      { required: true, message: "Document is required" }
    ],
    file_content: [
      { required: true, message: "File Content is required" }
    ],
    start_date: [
      { required: true, message: "Date added is required" }
    ],
    end_date: [
      { required: true, message: "Expiry date is required" },
      ({ getFieldValue }) => ({
        validator(value, rule) {
          const created_date = getFieldValue("start_date")
          const delete_date = getFieldValue("end_date")
          if (delete_date && (moment(created_date).format('YYYY-MM-DD') >= moment(delete_date).format('YYYY-MM-DD'))) {
            return Promise.reject("It cannot be same as the date added. They can only select greater than the date added. ");
          }
          return Promise.resolve();
        },
      })
    ],
    category_id: [
      { required: true, message: "Category is required" }
    ],
  }
}

const vsmHandoverSheet = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
  replicate: "Successfully replicated.",
  validation: {
    house_id: [
      { required: true, message: "Please select House" }
    ],
    from_date: [
      { required: true, message: "Please select From date" }
    ],
    to_date: [
      { required: true, message: "Please select To date" }
    ],
    day: [
      { required: true, message: "Please select Day" }
    ],
    shift: [
      { required: true, message: "Please select Shift" }
    ],
    task: [
      { required: true, message: "Task is required" }
    ],
  }
}

const vsmRiskAssessment = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
  validation: {
    service_user_id: [
      { required: true, message: "Please select Service User" }
    ],
    house_id: [
      { required: true, message: "Please select House" }
    ],
    next_review_date: [
      { required: true, message: "Please select Review date" }
    ],
    implimentation_date: [
      { required: true, message: "Please select Completed date" }
    ],
    hazard_name: [
      { required: true, message: "Please select Hazard Name" }
    ],
    who_harmed: [
      { required: true, message: "It is required" }
    ],
    risk_provoking_factors: [
      { required: true, message: "It is required" }
    ],
    risk_managed: [
      { required: true, message: "It is required" }
    ],
    risk_factor: [
      { required: true, message: "It is required" }
    ],
  }
}

const vsmNotification = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
}

const vsmSystemForms = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
  validation: {
    service_user_id: [
      { required: true, message: "Please select Service User" }
    ],
    date_incident: [
      { required: true, message: "Please select Date of Incident" }
    ],
    category_behaviour: [
      { required: true, message: "Please select Category of behaviour" }
    ],
    describe_behaviour: [
      { required: true, message: "Describe behaviour is required" }
    ],
    involved_activity: [
      { required: true, message: "Please select Involved in Activity" }
    ],
    engage_activity: [
      { required: true, message: "Please select Prompting to Engage in Activity" }
    ],
    atmosphere: [
      { required: true, message: "Please select atmosphere" }
    ],
    noise_level: [
      { required: true, message: "Please select Noise Level" }
    ],
    apparent_mood: [
      { required: true, message: "Please select Service user’s apparent mood" }
    ],
    activity_selected: [
      { required: true, message: "Please select Activity selected by" }
    ],
    service_user_seem: [
      { required: true, message: "Please select Service user seem" }
    ],
    person_proximity: [
      { required: true, message: "Please select Person proximity" }
    ],
    time: [
      { required: true, message: "Time is required" }
    ],
    place: [
      { required: true, message: "Place is required" }
    ],
    people_present: [
      { required: true, message: "People present is required" }
    ],
    antecedent: [
      { required: true, message: "Antecedent is required" }
    ],
    behaviour: [
      { required: true, message: "Behaviour is required" }
    ],
    consequence: [
      { required: true, message: "Consequence is required" }
    ],
    list_of_event: [
      { required: true, message: "It is required" }
    ],
    additional_information: [
      { required: true, message: "Additional information is required" }
    ],
    managers_comments: [
      { required: true, message: "Managers comments is required" }
    ],
    staff: [
      { required: true, message: "staff is required" }
    ],
    staff_sign_date: [
      { required: true, message: "Date is required" }
    ],
    man_sign_date: [
      { required: true, message: "Date is required" }
    ],
    authorised_id: [
      { required: true, message: "Authorised id is required" }
    ],
    mandatory: [
      { required: true, message: "It is required" }
    ],
    house_id: [
      { required: true, message: "House is required" }
    ]
  }
}

const vsmSafeguardLog = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
  validation: {
    house_id: [
      { required: true, message: "Please select House" }
    ],
  }
}

const vsmAuditForm = {
  add: "Successfully created.",
  edit: "Successfully updated.",
  delete: "Successfully deleted.",
  validation: {
    audit_name: [
      { required: true, message: "Audit Name is required" }
    ],
    audit_date: [
      { required: true, message: "Audit Date is required" }
    ],
    house_id: [
      { required: true, message: "House is required" }
    ],
    action_taken: [
      { max: 255, message: "It must not be greater than 255 words."}
    ]
  }
}

const vsmDailyLogs = {
  start_date:[
    ({ getFieldValue }) => ({
      validator(_, value) {
        const startDate = getFieldValue('startDate');
        const endDate = getFieldValue('endDate');
        if (!startDate || !endDate) {
          return Promise.resolve();
        }
        if (moment(endDate).isSameOrBefore(startDate)) {
          return Promise.reject(new Error('End date must be same or after start date.'));
        }
        if (moment(endDate).diff(moment(startDate), 'months', true) > 3) {
          return Promise.reject(new Error('The date range must not be greater than 3 months.'));
        }
        return Promise.resolve();
      },
    })
  ]
}

export {
  vsmNotify,
  vsmAuth,
  vsmCommon,
  vsmContact,
  vsmUserDetails,
  vsmHousing,
  vsmServiceUsers,
  vsmServiceUserTab,
  complainLog,
  vsmServiceDailyLogTab,
  vsmServiceDocumentTab,
  vsmHandoverSheet,
  vsmRiskAssessment,
  vsmNotification,
  vsmSystemForms,
  vsmSafeguardLog,
  vsmAuditForm,
  vsmDailyLogs
}
