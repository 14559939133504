import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../config/LocalGridConfig";

export default class ContactInfoTabStore {
  agGrid = null;
  List_data = [];
  total = 1;
  rolePermissionsData = []
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;
  editValues = null
  deleteValues = null

  setupGrid = (params) => {
    this.agGrid = params
  }

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  getList = (id) => {
    return Axios.post(`/api/service_user/edit/contact/list/${id}`).then(({ data }) => {
      data.data.forEach((item) => {
        item.fullname = (item.first_name ? item.first_name : "") + " " + (item.last_name ? item.last_name : "")
      })
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
      return data
    })
  }

  AddContactDetail = (data, parentId) => {
    return Axios.post(`/api/service_user/edit/contact/add/${parentId}`, data).then(({ data }) => {
      this.getList(parentId)
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  EditContactDetail = (data, parentId, childId) => {
    return Axios.post(`/api/service_user/edit/contact/edit/${parentId}/${childId}`, data).then(({ data }) => {
      this.getList(parentId)
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  DeleteContactDetail = (data, parentId, childId) => {
    return Axios.post(`/api/service_user/edit/contact/delete/${parentId}/${childId}`, data).then(({ data }) => {
      this.getList(parentId)
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getCategoryList = () => {
    return Axios.get(`/api/user/edit/hrdetails/categorylist`).then(({ data }) => {
      return data
    })
  }

  onGridChanged = (params) => {
    localStorage.setItem('service_user_contact_tab', JSON.stringify(params.columnApi.getColumnState()))
  }
}

decorate(ContactInfoTabStore, {
  agGrid: observable,
  List_data: observable,
  editValues: observable,
  deleteValues: observable,
  ActiveStatus: action,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  onGridChanged: action,
  getCategoryList: action,
  AddContactDetail: action,
  EditContactDetail: action,
  JsonToFormData: action,
  setEditValues: action,
  setDeleteValues: action,
  DeleteContactDetail: action
})