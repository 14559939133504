import {
  getMessaging, getToken,
  onMessage
} from 'firebase/messaging';
import { initializeApp } from 'firebase/app';
import { notification } from 'antd';
//import { Notify } from "./utils/Notify";
// import axios from "axios";

export const getTokenInit = async () => {



  // console.log("first : ", process.env.REACT_APP_FIREBASE_MESSAGING_API_KEY)
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    // apiKey: process.env.React_APP_apiKey,
    // authDomain: process.env.React_APP_authDomain,
    // databaseURL: process.env.React_APP_databaseURL,
    // projectId: process.env.React_APP_projectId,
    // storageBucket: process.env.React_APP_storageBucket,
    // messagingSenderId: process.env.React_APP_messagingSenderId,
    // appId: process.env.React_APP_appId,
    // measurementId: process.env.React_APP_measurementId
    apiKey: process.env.REACT_APP_FIREBASE_MESSAGING_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_MESSAGING_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_MESSAGING_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_MESSAGING_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MESSAGING_MEASUREMENT_ID,
  }

  initializeApp(firebaseConfig);
  const messaging = getMessaging();
  const publicKey = process.env.REACT_APP_FIREBASE_MESSAGING_VAPID_KEY

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('firebase-messaging-sw.js')
      .then(function (registration) {
        // console.log(
        // 	'test Registration successful, scope is:',
        // 	registration.scope
        // );
      })
      .catch(function (err) {
        // console.log('test Service worker registration failed, error:', err);
      });
  }

  getToken(messaging, { vapidKey: publicKey })
    .then((currentToken) => {
      localStorage.setItem("fcmToken", currentToken)
      // if (currentToken) {
      // 	axios.post('save-token', { token: currentToken });
      // } else {
      // 	console.warn(
      // 		'No registration token available. Request permission to generate one.'
      // 	);
      // }
    })
    .catch((err) => {
      console.warn('An error occurred while retrieving token. ', err);
    });

  // let count = 1
  onMessage(messaging, (payload) => {

    console.log("payload1", payload)
    // console.log("onscreen", count + 1),

    localStorage.setItem("notificationCount", (parseInt(localStorage.getItem("notificationCount")) ?? 0) + 1);
    window.dispatchEvent(new Event("storage"));
    // resolve(payload)
    notification.open({
      message: payload.notification.title,
      description: payload.notification.body,
    });
    // <NotificationComponent message={payload.notification.title} description={payload.notification.body} />
  });

  // new Promise((resolve) => {
  //   onMessage(messaging, (payload) => {
  //     console.log("payload1", payload)
  //     resolve(payload);
  //   })
  // })

};
