import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../config/LocalGridConfig";

export default class SettingStore {
  agGrid = null;
  List_data = [];
  total = 1;
  rolePermissionsData = []
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;
  dropdown_form_list = null
  dropdown_category_list = null

  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  getList = () => {
    return Axios.get(`/api/setting/list`).then(({ data }) => {
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
      return data
    })
  }


  AddSetting = (data) => {
    return Axios.post(`/api/setting/add`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getSystemFormsList = () => {
    return Axios.get(`api/systemforms/dropdownTemplates`)
      .then(({ data }) => {
        this.dropdown_form_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  getCategoryList = () => {
    return Axios.get(`api/category/getformCategory`)
      .then(({ data }) => {
        this.dropdown_category_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }



  onGridChanged = (params) => {
    localStorage.setItem('setting_form_list', JSON.stringify(params.columnApi.getColumnState()))
  }
}

decorate(SettingStore, {
  agGrid: observable,
  List_data: observable,
  dropdown_form_list: observable,
  dropdown_category_list: observable,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  AddSetting: action,
  onGridChanged: action,
  JsonToFormData: action,
  getSystemFormsList: action,
  getCategoryList: action
})