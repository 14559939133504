import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../../config/LocalGridConfig";
import { IsChecked, IsMandatory } from "../../../config/globalsFunc";

export default class HrDetailStore {
  agGrid = null;
  List_data = [];
  total = 1;
  rolePermissionsData = []
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;

  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  getList = (currentUserDetailId) => {
    return Axios.post(`/api/user/edit/hrdetails/list/${currentUserDetailId}`).then(({ data }) => {
      data.data.forEach((item) => {
        item.is_checked_status = item.is_checked === 1 ? IsChecked[1] : IsChecked[0]
        item.is_mandatory_status = item.is_mandatory ? IsMandatory[1] : IsMandatory[2]
      })
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
      return data
    })
  }

  AddHrDetails = (result, data, id) => {
    return Axios.post(`/api/user/edit/hrdetails/add/${data.id}`, result).then(({ data }) => {
      this.getList(id)
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  EditHrDetails = (formData, result) => {
    return Axios.post(`/api/user/edit/hrdetails/edit/${result.id}/${result.actionId}`, formData).then(({ data }) => {
      this.getList(result.id)
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getCategoryList = () => {
    return Axios.get(`/api/user/edit/hrdetails/categorylist`).then(({ data }) => {
      return data
    })
  }

  onGridChanged = (params) => {
    localStorage.setItem('Hr_details', JSON.stringify(params.columnApi.getColumnState()))
  }
}

decorate(HrDetailStore, {
  agGrid: observable,
  List_data: observable,
  ActiveStatus: action,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  onGridChanged: action,
  getCategoryList: action,
  AddHrDetails: action,
  EditHrDetails: action,
  JsonToFormData: action
})