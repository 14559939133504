import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../config/messages";
import ServerSideGridConfig from "../../config/LocalGridConfig";
import { ComplainStatus } from "../../config/globalsFunc";

export default class ReportDailyStore {
  agGrid = null;
  List_data = [];
  total = 1;
  per_page = ServerSideGridConfig.options.paginationPageSize;
  current_page = 1;

  getList = (payload) => {
    return Axios.post(`/api/reports/dailylog`, payload)
      .then(({ data }) => {
        if (data.data) {
          data.data.forEach((item, index) => {
            item.is_active_display =
              ComplainStatus[Number(item.complain_status)];
            item.section = item.category_id ? item.category_name : null;
            item.service_by = item.service_id
              ? item.service_first_name + " " + item.service_last_name
              : null;
            item.last_updated_by = item.staff_id
              ? item.staff_first_name + " " + item.staff_last_name
              : null;
          });
        }
        if (data?.length) {
          let startPage = (data.current_page - 1) * data.per_page;
          data.data.map((item, index) => {
            item.sr_no = startPage + index + 1;
            return null;
          });
        }
        this.List_data = data.data;
        this.total = data.total;
        this.current_page = data.current_page;
        return data;
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  doExportDownloadDetail = (payload) => {
    return Axios.post("/api/user/edit/note/export_data", payload)
      .then(({ data }) => {
        if(data.export_url){
          const link = document.createElement("a");
          link.href =
            process.env.REACT_APP_BASE_URL +
            data.export_url.replace("/var/www", "/api");
          let parts = data.export_url.split("/");
          let filename = parts[parts.length - 1];
          link.setAttribute("download", filename);
          document.body.appendChild(link);
          link.click();
        }
        vsmNotify.success({ message: data.message });
        return data;
      })
      .catch(({response: {data}}) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          vsmNotify.error({ message: data.errors[name][0] });
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params;
    const { api } = params;
    let datasource = this.createDatasource(ServerSideGridConfig.options);
    api.setServerSideDatasource(datasource);
  };

  // Filter function for no record found message
  changeFilterAndSort = (params) => {
    var final_filter = params.filterModel;
    var final_sort = params.sortModel;
    return { final_filter, final_sort };
  };

  // Create data source to display record in table
  createDatasource = (gridOptions) => {
    return {
      gridOptions,
      getRows: (params) => {
        var filter_data = this.changeFilterAndSort(params.request);
        var payload = {
          filter_data: filter_data.final_filter,
          sort_data: filter_data.final_sort,
          per_page: params.request.endRow - params.request.startRow,
          page: Math.ceil(
            (params.request.startRow + 1) /
              (params.request.endRow - params.request.startRow)
          ),
        };
        this.getList(payload).then((data) => {
          if (data.total === 0) {
            this.agGrid.api.showNoRowsOverlay();
          } else {
            this.agGrid.api.hideOverlay();
          }
          params.successCallback(data.data, data.total);
          var allColumnIds = [];
          if (this.agGrid && this.agGrid.columnApi && data.total) {
            this.agGrid.columnApi.getAllColumns().forEach(function (column) {
              allColumnIds.push(column.col_id);
            });
          }
        });
      },
    };
  };

  // Filter function for no record found message
  onFilterChangedList = (params) => {
    this.agGrid = params;
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() === 0) {
      this.agGrid.api.showNoRowsOverlay();
    }
    if (this.agGrid && this.agGrid.api.getModel().getRowCount() > 0) {
      this.agGrid.api.hideOverlay();
    }
  };

  // change page size, default page size is GridConfig.options.paginationPageSize
  setPageSize = (page = this.per_page) => {
    this.per_page = page;
    if (this.agGrid) {
      this.agGrid.api.paginationSetPageSize(parseInt(page));
    }
  };

  onGridChanged = (params) => {
    localStorage.setItem(
      "report_daily_log",
      JSON.stringify(params.columnApi.getColumnState())
    );
  };

  AddComplain = (e) => {
    return Axios.post(`/api/complaint_log/add`, e)
      .then(({ data }) => {
        this.setupGrid(this.agrid);
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            if (name === "both") {
              notify = data.errors[name][0];
            } else {
              errors.push({ name, errors: data.errors[name] });
            }
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  //Edit Data
  EditComplain = (formdata, id) => {
    return Axios.post(`/api/complaint_log/edit/${id}`, formdata)
      .then(({ data }) => {
        this.setupGrid(this.agrid);
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  ActiveStatus = (id, status) => {
    Axios.post(`/api/contact/status/${id}`, { status: status ? 0 : 1 })
      .then(({ data }) => {
        if (data.status === 200) {
          this.getList();
          vsmNotify.success({ message: data.message });
        }
      })
      .catch((err) => {
        return;
      });
  };

  DeleteComplain = (id) => {
    return Axios.post(`/api/complaint_log/delete/${id}`)
      .then(({ data }) => {
        this.getList();
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        var notify = null;
        if (data.errors) {
          Object.keys(data.errors).forEach((name) => {
            if (name === "both") {
              notify = data.errors[name][0];
            } else {
              errors.push({ name, errors: data.errors[name] });
            }
          });
        }
        return Promise.reject({ errors, notify });
      });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach((key) => {
      if (![document].includes(key)) {
        formData.append(
          key,
          data[key] !== undefined && data[key] !== null ? data[key] : ""
        );
      }
    });
    return formData;
  };

  exportCsv = () => {
    var params = {
      columnKeys: [
        "id",
        "service",
        "notes",
        "document",
        "title",
        "notes_date",
        "category",
      ],
    };
    this.agGrid.api.exportDataAsCsv(params);
  };
}

decorate(ReportDailyStore, {
  List_data: observable,
  agGrid: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  getList: action,
  setPageSize: action,
  setupGrid: action,
  createDatasource: action,
  onGridChanged: action,
  AddContact: action,
  EditComplain: action,
  AddComplain: action,
  DeleteComplain: action,
  ActiveStatus: action,
  JsonToFormData: action,
  onFilterChanged: action,
  exportCsv: action,
});
