import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../../config/messages";
import LocalGridConfig from "../../../config/LocalGridConfig";
import { isActiveStatus } from "../../../config/globalsFunc";

export default class ManageTownStore {    
    List_data = [];
    total = 1;    
    per_page = LocalGridConfig.options.paginationPageSize
    current_page = 1  
    agGrid=null


       // Setup grid and set column size to autosize
    setupGrid = (params) => {        
        this.agGrid = params      
    }
    
    setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {        
        this.per_page = page
        this.agGrid.api.paginationSetPageSize(parseInt(page))
    }

    onGridChanged = (params) => {
        localStorage.setItem('manage town', JSON.stringify(params.columnApi.getColumnState()))
    }


    getList = () => {        
        return Axios.post(`/api/town/list`).then(({ data }) => {                        
            if (data.data) {
				data.data.forEach((item, index) => {	                   			                    
					item.is_active_display =
						item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
				});
			}
            this.List_data = data.data
			this.total = data.total
			this.current_page = data.current_page			
            })
    }    

    EditMasterTown = (data) => {
        return Axios.post(`/api/town/edit/${data.id}`,{name:data.name}).then(({data})=>{            
                this.getList()                               
        }).catch(({ response: { data } }) => {            
            var errors = []
            var notify = null
            if (data.errors) {
                Object.keys(data.errors).forEach(name => {
                    if (name === 'both') {
                        notify = data.errors[name][0]
                    } else {
                        errors.push({ name, errors: data.errors[name] })
                    }
                })
            }                           
            return Promise.reject({ errors, notify })
        })
    }

    ActiveStatus = (id,status) => {        
        Axios.post(`/api/town/status/${id}`,{"status": status ? 0 : 1 }).then(({data})=>{
            if(data.status===200)
            {
                this.getList()   
                vsmNotify.success({ message: data.message})
            }            
        }).catch((err)=>{ return })
    }

    DeleteMasterTown = (id) => {        
        Axios.post(`/api/town/delete/${id}`).then(({data})=>{
            if(data.status===200)
            {
                this.getList()   
                vsmNotify.success({message:'successfully Deleted'})                
            }            
        }).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
    }

    AddMasterTown = (e) => {        
        return Axios.post(`/api/town/add`,e).then(({data})=>{            
                this.getList()                   
        }).catch(({ response: { data } }) => {                          
            var errors = []
            var notify = null
            if (data.errors) {
                Object.keys(data.errors).forEach(name => {
                    if (name === 'both') {
                        notify = data.errors[name][0]
                    } else {
                        errors.push({ name, errors: data.errors[name] })
                    }
                })
            }                           
            return Promise.reject({ errors, notify })
        })
    }    
 }
 
decorate(ManageTownStore,{    
    List_data : observable,
    getList : action,
    setPageSize:action,
    total:observable,
    current_page:observable,
    per_page:observable,
    setupGrid : action,
    ActiveStatus : action,
    createDatasource:action,
    AddMasterTown:action,
    agGrid: observable,
    EditMasterTown:action,
    DeleteMasterTown : action,
    onGridChanged:action
})