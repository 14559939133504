import React from 'react'
import { Route, Switch } from 'react-router'
import { Apps_RouterConfig } from '../config/RouterConfig'
import PageNotFound from '../pages/CommonPages/PageNotFound'
import useStore from '../store'

const CoreprosAppRouter = () => {
  const { AppsAuthStore: { prospects_user } } = useStore()
  return (
    <Switch>
      {
        Apps_RouterConfig.map((item, index) => {
          var exact = true
          if (item.errorpage === true) {
            exact = false
          }

          if (prospects_user && prospects_user.user_role && /* originator_user.user_role.user_role_id !== 1} && */[].includes(item.path)) {
            return (
              <Route key={index} exact={exact} path={item.path}>
                <PageNotFound />
              </Route>
            )
          } else {
            return (
              <Route key={index} exact={exact} path={item.path} component={item.component} />
            )
          }

        })
      }
    </Switch>
  )
}

export default CoreprosAppRouter;





