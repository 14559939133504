export const Patterns = {
    PHONE: /^[0-9 /()-]*$/,
    MOBILE: /^[0-9]*$/,
    PINCODE: /^[0-9]{6}$/,
    USERNAME: /^[A-Za-z0-9_.-]*$/,
    TEXT_WITH_ONE_SPACE: /(?!^\s+$)^.*$/,
    ACCOUNT: /^([0-9]+)$/,
    EMAIL: /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/,
    PASSWORD: /^[a-zA-Z0-9!@#$%^&*()-.+,/_"\\]*$/,
    PAN: /^([A-Z]){5}([0-9]){4}([A-Z]){1}?$/,
    GSTIN: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}[Z]{1}[A-Z0-9]{1}$/,
    FLOATVALUE: /^-?\d*(\.\d{2})?$/,
    NUMBER: /^[0-9\b]+$/,
    ALPHANUMERIC: /^[A-Za-z0-9]*$/,
}