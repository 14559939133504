import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { RiskAssessmentStatusList } from "../../config/globalsFunc";
import LocalGridConfig from "../../config/LocalGridConfig";

export default class RiskAssessmentStore {
  agGrid = null;
  List_data = [];
  total = 1;
  rolePermissionsData = []
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;
  editValues = null
  deleteValues = null
  viewValues = null
  viewLogValues = null
  dropdown_house_list = null
  dropdown_service_user_list = null
  dropdown_hazard_list = null
  dropdown_hazard_data = null
  risk_assessment_pdf = null

  setupGrid = (params) => {
    this.agGrid = params
  }

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to view
  setViewValues = (id) => {
    return Axios.get(`/api/riskassesments/${id}`).then(({ data }) => {
      this.viewValues = data.data[0];
    })
  }

  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  setReplicateValues = (data) => {
    this.replicateValues = data;
  }


  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  getList = () => {
    return Axios.get(`/api/riskassesments/list`).then(({ data }) => {
      data.data.forEach((item) => {
        // if (item.created_by) {
        //   item.createdBy = item.created_by?.first_name + " " + item.created_by?.last_name
        // }
        item.status_name = RiskAssessmentStatusList[item.status]
      })
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
      return data
    })
  }

  getServiceUserList = () => {
    return Axios.get(`api/serviceuser/serviceuserlist`)
      .then(({ data }) => {
        this.dropdown_service_user_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  getHouseList = () => {
    return Axios.get(`api/house/userwisehouselist`)
      .then(({ data }) => {
        this.dropdown_house_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  getHazardList = () => {
    return Axios.get(`api/riskassementlist`)
      .then(({ data }) => {
        this.dropdown_hazard_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  getHazardData = (id) => {
    return Axios.get(`api/riskassesments/search/${id}`)
      .then(({ data }) => {
        this.dropdown_hazard_data = data.search[0];
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  AddRiskAssessmentSheet = (data) => {
    return Axios.post(`/api/riskassesments/add`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  EditRiskAssessment = (id, data) => {
    return Axios.post(`/api/riskassesments/edit/${id}`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  DeleteRiskAssessment = (id, data) => {
    return Axios.post(`/api/riskassesments/delete/${id}`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  ReplicateListRow = (data) => {
    return Axios.post(`/api/riskassesments/replicate/${data.id}`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }


  viewLogList = (id) => {
    return Axios.get(`/api/riskassesments/viewlog/${id}`).then(({ data }) => {
      this.viewLogValues = data.data
      return data.data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getRiskAssessmentReport = (id) => {
    return Axios.get(`api/exportpdf/${id}`)
      .then(({ data }) => {
        this.risk_assessment_pdf = data.link;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }


  onGridChanged = (params) => {
    localStorage.setItem('risk_assessment_list', JSON.stringify(params.columnApi.getColumnState()))
  }
}

decorate(RiskAssessmentStore, {
  agGrid: observable,
  List_data: observable,
  editValues: observable,
  deleteValues: observable,
  viewValues: observable,
  viewLogValues: observable,
  dropdown_house_list: observable,
  dropdown_service_user_list: observable,
  dropdown_hazard_list: observable,
  dropdown_hazard_data: observable,
  risk_assessment_pdf: observable,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  AddRiskAssessmentSheet: action,
  EditRiskAssessment: action,
  DeleteRiskAssessment: action,
  viewLogList: action,
  onGridChanged: action,
  JsonToFormData: action,
  setEditValues: action,
  setDeleteValues: action,
  setViewValues: action,
  getHouseList: action,
  getServiceUserList: action,
  getHazardList: action,
  getHazardData: action,
  ReplicateListRow: action,
  getRiskAssessmentReport: action,
})