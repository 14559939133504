import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { ServiceUserStatusList } from "../../config/globalsFunc";
// import { vsmNotify } from "../../config/messages";
import LocalGridConfig from "../../config/LocalGridConfig";

export default class ServiceUsersStore {
  List_data = [];
  total = 1;
  agGrid = null;
  currentUserDetailId = null
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;
  statusData = null;
  editValues = null;
  formType = null;

  setEditValues = (data) => {
    this.editValues = data;
    return data;
  }

  setupGrid = (params) => {
    this.agGrid = params
  }

  setFormType = (params) => {
    this.formType = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  onGridChanged = (params) => {
    localStorage.setItem('service_user_list', JSON.stringify(params.columnApi.getColumnState()))
  }

  onFilterChanged = () => {
    if (this.agGrid && this.agGrid.api.rowModel.rowsToDisplay.length === 0) {
      this.agGrid.api.showNoRowsOverlay();
    } else {
      this.agGrid.api.hideOverlay();
    }
  };

  getList = () => {
    return Axios.post(`/api/serviceuser/list`).then(({ data }) => {
      if (data) {
        data.data.forEach((item) => {
          item.fullname = item.first_name + " " + item.last_name
          item.is_active_status = ServiceUserStatusList[item.status]
        });
      }
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
      return data
    })
  }

  AddUser = (data) => {
    return Axios.post(`/api/serviceuser/add`, data).then(({ data }) => {
      // this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }


  EditUser = (formdata, id) => {
    return Axios.post(`/api/serviceuser/edit/${id}`, formdata).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }


  //Update Status
  updateStatus = (formdata) => {
    return Axios.post(`/api/serviceuser/status/${formdata.id}`, { status: formdata.status })
      .then(({ data }) => {
        this.getList()
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  //User Read API
  ReadUser = (ID) => {
    return Axios.post(`/api/serviceuser/${ID}`).then(({ data }) => {
      return data.data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  //User Delete API
  DeleteUser = (ID) => {
    return Axios.post(`/api/serviceuser/delete/${ID}`).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

}

decorate(ServiceUsersStore, {
  agGrid: observable,
  List_data: observable,
  currentUserDetailId: observable,
  statusData: observable,
  editValues: observable,
  formDataPayload: observable,
  ActiveStatus: action,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  onGridChanged: action,
  AddUser: action,
  EditUser: action,
  ReadUser: action,
  DeleteUser: action,
  getRolesList: action,
  RoleReadPermission: action,
  updateStatus: action,
  setEditValues: action,
  setFormDataPayload: action,
})