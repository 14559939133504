import Axios from "axios";
import { action, decorate, observable } from "mobx";
import LocalGridConfig from "../../config/LocalGridConfig";

export default class HandoverSheetStore {
  agGrid = null;
  List_data = [];
  total = 1;
  rolePermissionsData = []
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;
  editValues = null
  deleteValues = null
  viewValues = null
  assignValues = null
  viewLogValues = null
  replicateValues = null
  dropdown_house_list = null
  dropdown_user_list = null

  StatusList = [
    { id: 0, name: 'All' },
    { id: 1, name: 'Active' },
    { id: 2, name: 'Draft' },
    { id: 3, name: 'Inactive' },
  ];

  setupGrid = (params) => {
    this.agGrid = params
  }

  // set form values to edit
  setEditValues = (data) => {
    this.editValues = data;
  };

  // set form values to view
  setViewValues = (data) => {
    this.viewValues = data;
  }

  // set form values to delete
  setDeleteValues = (data) => {
    this.deleteValues = data;
  };

  setAssignTaskValues = (data) => {
    this.assignValues = data;
  }

  setReplicateValues = (data) => {
    this.replicateValues = data;
  }


  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  getList = () => {
    return Axios.get(`/api/handover/list`).then(({ data }) => {
      data.data.forEach((item) => {
        if (item.created_by) {
          item.createdBy = item.created_by?.first_name + " " + item.created_by?.last_name
        }
        item.is_status = item.status >= 0 ? this.StatusList[item.status].name : null
        item.count = item.out_of_handoversheet_count + "/" + item.handoversheet_count
      })
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
      return data
    })
  }

  getHouseList = () => {
    return Axios.get(`api/house/gethouselist`)
      .then(({ data }) => {
        this.dropdown_house_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  getUserList = () => {
    return Axios.post(`api/user/userlist`)
      .then(({ data }) => {
        this.dropdown_user_list = data;
        return data;
      })
      .catch(response => {
        return Promise.reject(response)
      })
  }

  AddHandoverSheet = (data) => {
    return Axios.post(`/api/handover/add`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  EditHandoverSheet = (id, data) => {
    return Axios.post(`/api/handover/edit/${id}`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  DeleteHandoverSheet = (id, data) => {
    return Axios.post(`/api/handover/delete/${id}`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  UpdateAssignedTask = (id, data) => {
    return Axios.post(`/api/handover/updateassign/${id}`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  viewLogList = (id) => {
    return Axios.get(`/api/handover/viewlog/${id}`).then(({ data }) => {
      this.viewLogValues = data.data
      return data.data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  ReplicateListRow = (data) => {
    return Axios.post(`/api/handover/replicate/${data.id}`, data).then(({ data }) => {
      this.getList()
      return data
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  //Update Status
  updateStatus = (formdata) => {
    return Axios.post(`/api/handover/status/${formdata.id}`, { status: formdata.status })
      .then(({ data }) => {
        this.getList()
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  onGridChanged = (params) => {
    localStorage.setItem('handover_sheet_list', JSON.stringify(params.columnApi.getColumnState()))
  }
}

decorate(HandoverSheetStore, {
  agGrid: observable,
  List_data: observable,
  editValues: observable,
  deleteValues: observable,
  viewValues: observable,
  assignValues: observable,
  viewLogValues: observable,
  replicateValues: observable,
  dropdown_house_list: observable,
  dropdown_user_list: observable,
  ActiveStatus: action,
  setupGrid: action,
  setPageSize: action,
  getList: action,
  onGridChanged: action,
  AddHandoverSheet: action,
  EditHandoverSheet: action,
  DeleteHandoverSheet: action,
  UpdateAssignedTask: action,
  JsonToFormData: action,
  setEditValues: action,
  setDeleteValues: action,
  setViewValues: action,
  setAssignTaskValues: action,
  setReplicateValues: action,
  getHouseList: action,
  getUserList: action,
  updateStatus: action
})