import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../config/messages";
import LocalGridConfig from "../../config/LocalGridConfig";
import { isActiveStatus } from "../../config/globalsFunc";

export default class HousingStore {
  agGrid = null;
  List_data = [];
  Doc_List_data = [];
  total = 1;
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;
  editValues = null;


  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  onGridChanged = (params) => {
    localStorage.setItem('house_list', JSON.stringify(params.columnApi.getColumnState()))
  }
  onGridChangedDoc = (params) => {
    localStorage.setItem('house_list_documents', JSON.stringify(params.columnApi.getColumnState()))
  }

  getList = () => {
    return Axios.post(`/api/house/list`).then(({ data }) => {
      if (data.data) {
        data.data.forEach((item, index) => {
          item.is_active_display = item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
          item.fullname = item.first_name + " " + item.last_name
        });
      }
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
    })
  }
  getDocList = (id) => {    
    return Axios.post(`/api/housedocument/edit/list/${id}`).then(({ data }) => {
      if (data.data) {
        data.data.forEach((item, index) => {
          item.is_active_display = item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
          item.fullname = item.first_name + " " + item.last_name
        });
      }
      this.Doc_List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
    })
  }

  AddHouse = (e) => {
    return Axios.post(`/api/house/add`, e).then(({ data }) => {
      this.getList()
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  handleHouseData = (id,history) => {
    return Axios.post(`/api/house/list`).then(({ data }) => {
      if (data.data) {
        data.data.forEach((item, index) => {          
          if(item.id===Number(id))
          {             
            this.editValues=item
          }
        });
      }
      if(this.editValues===null)
      {
        history.push('/housing')
        vsmNotify.error({message:`${id} id does not exists`})
      }            
    })   
  }

  setEditValues = (data) =>{
    this.editValues = data;
    return this.editValues;
  }
  //Edit Data
  EditHouse = (id, formdata) => {
    return Axios.post(`/api/house/edit/${id}`, formdata)
      .then(({ data }) => {
        this.getList()
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  ActiveStatus = (id, status) => {
    Axios.post(`/api/house/status/${id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
      if (data.status === 200) {
        this.getList()
        vsmNotify.success({ message: data.message })
      }
    }).catch((err) => { return })
  }
}

decorate(HousingStore, {
  List_data: observable,
  agGrid: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  editValues: observable,
  Doc_List_data: observable,
  handleHouseData:action,
  getList: action,
  setPageSize: action,
  setupGrid: action,
  createDatasource: action,
  onGridChanged: action,
  AddHouse: action,
  EditHouse: action,
  ActiveStatus: action,
  setEditValues: action,
  getDocList: action,
  onGridChangedDoc: action,
  JsonToFormData: action
})