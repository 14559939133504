import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { isActiveStatus } from "../../config/globalsFunc";
// import { vsmNotify } from "../../config/messages";
import LocalGridConfig from "../../config/LocalGridConfig";

export default class UserManagementStore {
	List_data = [];
	total = 1;
	agGrid = null;
	currentUserDetailId = null
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1;
	dropdownUserList = null;
	dropdown_users_list = null;

	setupGrid = (params) => {
		this.agGrid = params
	}

	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	getList = () => {
		return Axios.post(`/api/user/list`).then(({ data }) => {
			if (data) {
				data.data.forEach((item) => {
					item.role = item.role ? item.role.role_name : ''
					item.fullname = item.first_name + " " + item.last_name
					item.is_active_status = item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
				});
			}
			this.List_data = data.data
			this.total = data.total
			this.current_page = data.current_page
			return data
		})
	}

	getManagerList = () => {
		return Axios.get(`/api/managerlist`).then(({ data }) => {
			this.List_data = data
			this.total = data.total
			this.current_page = data.current_page
			return data
		})
	}

	AddUser = (data) => {
		return Axios.post(`/api/user/add`, data).then(({ data }) => {
			// this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}


	EditUser = (formdata, id) => {
		return Axios.post(`/api/user/edit/${id}`, formdata).then(({ data }) => {
			// this.getList()		
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	ReadUser = (Id) => {
		return Axios.get(`/api/user/${Id}`).then(({ data }) => {
			return data.data
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	DeleteUser = (Id) => {
		return Axios.post(`/api/user/delete/${Id}`).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	ActiveStatus = (Id, status) => {
		return Axios.post(`/api/user/status/${Id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
			this.getList()
			return data
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	RoleReadPermission = (Id) => {
		return Axios.post(`/api/permission/list`, { role_id: Id }).then(({ data }) => {
			this.Read_Data = data
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}


	onGridChanged = (params) => {
		localStorage.setItem('userManagement', JSON.stringify(params.columnApi.getColumnState()))
	}

	getUserList = () => {
		return Axios.post(`/api/user/list`).then(({ data }) => {
			if (data) {
				data.data.forEach((item) => {
					item.fullname = item.first_name + " " + item.last_name
				});
			}
			this.dropdownUserList = data.data
			return data
		})
	}

	getOnlyUsersList = () => {
		return Axios.post(`api/user/userlist`)
			.then(({ data }) => {
				this.dropdown_users_list = data;
				return data;
			})
			.catch(response => {
				return Promise.reject(response)
			})
	}

}

decorate(UserManagementStore, {
	List_data: observable,
	agGrid: observable,
	currentUserDetailId: observable,
	dropdownUserList: observable,
	dropdown_users_list: observable,
	ActiveStatus: action,
	setupGrid: action,
	setPageSize: action,
	getList: action,
	onGridChanged: action,
	AddUser: action,
	EditUser: action,
	ReadUser: action,
	DeleteUser: action,
	getRolesList: action,
	RoleReadPermission: action,
	getUserList: action,
	getOnlyUsersList: action
})