export const Messages = {
    LOGIN_SUCCESS: "Logged in successful",    
    LOGOUT_SUCCESS:'Logout successfully',    
    FIELD_MENDATORY:"it is mendatory",
    //master
    Add_Success : "Successfully created",
    Updated_Success :"Successfully updated",
    Status_Updated_Success :"Status successfully updated ",    
    Something_Went_Wrong :"Something went wrong!",        
} 

