const LocalGridConfig = {
	options: {
		animateRows: true,
		pagination: true,
		paginationPageSize: 50,
		rowHeight: 30,
		headerHeight: 32,
		floatingFiltersHeight: 32,
		enableRangeSelection: true,
		suppressDragLeaveHidesColumns: true,
	},
	defaultColDef: {
		resizable: true,
		sortable: true,
		filter: 'agTextColumnFilter',
		filterParams: {
			suppressAndOrCondition: true,
		},
		floatingFilter: true,
		flex: 1,
		rowHeight: 23,
	},
	columnTypes: {
		actionColumn: {
			cellRenderer: 'ActionRenderer'
		}
	},
}

export default LocalGridConfig
