import { observable, action, decorate } from 'mobx'
import LocalGridConfig from '../../config/LocalGridConfig';
import Axios from 'axios'

export default class AuditLogStore {
    list_data = []
    total = 1;
    per_page = LocalGridConfig.options.paginationPageSize
    current_page = 1
    agGrid = null
    read_value = null
    user_List = []

    setupGrid = (params)=>{
       this.agGrid = params
    }
  
    setPageSize = (page = LocalGridConfig.options.paginationPageSize)=>{
        this.per_page = page
        this.agGrid.api.paginationSetPageSize(parseInt(page))   
    }

    onGridChanged = (params)=>{
        console.log(params)
        localStorage.setItem('audit_token', JSON.stringify(params.columnApi.getColumnState()))
    }
    onFilterChanged=(params)=>{
        if(params.api.rowModel.rowsToDisplay.length === 0){
         this.agGrid.api.showNoRowsOverlay()
        }
        else {
         this.agGrid.api.hideOverlay()
        }
     }

    exportCsv = () => {
        var params = {
          columnKeys: ['id', 'user_type', 'full_name', 'event', 'auditable_type','auditable_id','url','ip_address','user_agent','Tags', 'Last_Updated_on']
      };
        this.agGrid.api.exportDataAsCsv(params)
      }

     
     

    getList = (payload={})=>{
        return Axios.post('/api/audit_log/list', payload).then(({data})=>{
            data.data.forEach((item)=>{
            item.full_name = item.user_name.first_name + " " + item.user_name.last_name
            })
           console.log("Data is", data)
           this.list_data = data.data
           this.current_page = data.current_page
        }).catch(({ response: { data } }) => {
            var errors = []
            var notify = null
            if (data.errors) {
              Object.keys(data.errors).forEach(name => {
                if (name === 'both') {
                  notify = data.errors[name][0]
                } else {
                  errors.push({ name, errors: data.errors[name] })
                }
              })
            }
            return Promise.reject({ errors, notify })
          })
    }

    handleReset = ()=>{
      localStorage.removeItem('audit_token')
      this.agGrid.api.setFilterModel(null)
      this.agGrid.api.onFilterChanged(null)
      this.agGrid.columnApi.resetColumnState()
      this.getList({'user_id': null , 'date': null })
    }


    getUserList = (payload) => {
      return Axios.post(`/api/user/list`, payload).then(({ data }) => {
        this.user_List = data.data
        this.total = data.total
        this.current_page = data.current_page
        return data
      })
    }

    readList = (id)=>{
        return Axios.get(`/api/audit_log/${id}`).then(({data})=>{           
            this.read_value = data.data
            return data.data
        }).catch(({ response: { data } }) => {
            var errors = []
            var notify = null
            if (data.errors) {
              Object.keys(data.errors).forEach(name => {
                if (name === 'both') {
                  notify = data.errors[name][0]
                } else {
                  errors.push({ name, errors: data.errors[name] })
                }
              })
            }
            return Promise.reject({ errors, notify })
          })
    }



}

decorate(AuditLogStore,{
 list_data : observable,
 total: observable,
 per_page: observable,
 agGrid: observable,
 user_List:observable,
 getList: action,
 setPageSize: action,
 setupGrid: action,
 exportCsv: action,
 onGridChanged: action,
 onFilterChanged: action,
 handleReset: action,
 read_value: observable,
 readList: action,
 getUserList:action
})