import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { logos } from "../../pages/Reports/ImageUtils";
import Excel from 'exceljs'
import { saveAs } from "file-saver";
import moment from "moment";

export default class TrainingMatrixStore {
  HouseList = []
  List_Data = []
  Training_List = [];
  matrixList = [];
  RoleList = [];

  Status = ['Pending', 'In Progress', 'Completed']

  TrainingMatrixApi = (payload) => {
    return Axios.post('/api/trainingmatrix', payload).then((data) => {
      this.List_Data = data.data.data;
      let newTraningList = [{ name: 'Name', id: 999999 }, { name: 'Role', id: 999998 }];
      if (data.data.data) {
        data.data.data.map((obj) => {
          if (obj.training.length > 0) {
            obj.training.map((childObj) => {
              let currentIndex = newTraningList.findIndex(x => x.name === childObj.name);
              if (currentIndex === -1) {
                newTraningList.push({ id: childObj.id, name: childObj.name, username_array: [obj.username], document_obj: childObj });
              }
              else {
                if (!newTraningList[currentIndex]["username_array"].includes(obj.username)) {
                  newTraningList[currentIndex]["username_array"].push(obj.username);
                }
              }
              return null;
            })
          }
          return null;
        })
        this.matrixList = newTraningList;
      }
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  getHouseList = () => {
    return Axios.get(`/api/house/userwisehouselist`).then(({ data }) => {
      if (data) {
        this.HouseList = data
        return data
      }
    })
  }

  getRoleList = () => {
    return Axios.post(`/api/role/list`).then(({ data }) => {
      if (data.data) {
        this.RoleList = data.data
      }
    })
  }

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    var offset;
    for (offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      var i;
      for (i = 0; i < slice.length; i = i + 1) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new window.Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    var blob = new window.Blob(byteArrays, {
      type: contentType
    });
    return blob;
  }

  exportTraingingSheet = async () => {
    var ss = []
    var headers = []
    this.matrixList.forEach((obj, mainIndex) => {
      ss.push(obj.name)
      this.List_Data.forEach((childObj) => {
        if ([0, 1].includes(mainIndex)) {
          var dd = (mainIndex === 0 ? childObj.username : childObj.role_name)
          headers.push(dd)
          ss.push(dd)
        }
        else {
          if (obj.username_array.includes(childObj.username)) {
            childObj.training.forEach((item) => {
              if (obj.document_obj.name === item?.name) {
                ss.push(
                  `${item.expiry_date ? item.expiry_date : "N/A"}`)
              }
            })
          }
          else {
            ss.push('N/A')
          }
        }
      })
      ss.push('\r')
    })

    var data = []
    var mainArray = []
    ss.forEach((item) => {
      if (item !== "\r") {
        data.push(item)
      }
      else {
        mainArray.push(data)
        data = []
      }
    })
    var headersData = []
    var detailsData = []
    var newData = []
    if (mainArray.length > 0) {
      const columnsStyle = { font: { name: 'Arial', size: 12 } };
      mainArray[0].forEach((item, index) => {
        newData.push(item)
        headersData.push({ key: item.split(' ').join('_'), style: columnsStyle, alignment: { wrapText: true, vertical: 'middle', shrinkToFit: true, horizontal: 'center' } })

      })
      mainArray.splice(0, 1)
    }
    if (mainArray.length > 0) {
      mainArray.forEach((info, mainIndex) => {
        let abs = {}
        info.forEach((child, index) => {
          abs[headersData[index].key] = child
        })

        detailsData.push(abs)
      })
    }

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('workSheetName');
    const logo = workbook.addImage({
      base64: logos.AgGrid,
      extension: 'png',
    });

    worksheet.addImage(logo, 'A1:A6');
    worksheet.getRow(8).values = newData
    worksheet.mergeCells('A1:D1');
    worksheet.mergeCells('A6:D6');

    worksheet.columns = headersData

    worksheet.columns.forEach(column => {
      column.width = column.key.length + 50;
      column.alignment = { horizontal: 'left' };
    });

    detailsData.forEach(item => {
      worksheet.addRow(item)
    })

    // worksheetrow.height = 42.5;
    worksheet.eachRow({ includeEmpty: false }, (row, index) => {
      const currentCell = row._cells;
      currentCell.forEach(singleCell => {
        row.height = 24;
        worksheet.getCell(singleCell._value.model.address).protection = {
          locked: true,
          hidden: true,
        };

        // Header Row
        if (index === 8) {
          worksheet.getRow(index).fill = {
            type: 'pattern',
            pattern: 'solid',
            // bgColor:{argb:'89CFF0'},
            fgColor: { argb: '444444' },
          };
          worksheet.getRow(index).border = {
            top: { style: 'thin', color: { argb: 'dddddd' } },
            left: { style: 'thin', color: { argb: 'dddddd' } },
            bottom: { style: 'thin', color: { argb: 'dddddd' } },
            right: { style: 'thin', color: { argb: 'dddddd' } }
          };
          worksheet.getRow(index).font = {
            name: 'Arial',
            color: { argb: 'ffffff' },
            family: 2,
            size: 12,
            bold: true
          };
        }


        // Data Col
        if (
          moment(singleCell._value.model.value, "YYYY-MM-DD", true).isValid()
          && moment(singleCell._value.model.value).format('YYYY-MM-DD') !== "Invalid date"
        ) {

          // Even Row
          if (index % 2 === 0) {
            worksheet.getRow(index).border = {
              top: { style: 'medium', color: { argb: 'dddddd' } },
              left: { style: 'medium', color: { argb: 'dddddd' } },
              bottom: { style: 'medium', color: { argb: 'dddddd' } },
              right: { style: 'medium', color: { argb: 'dddddd' } }
            };
          }

          if (moment(singleCell._value.model.value).isBefore(new Date())) {
            worksheet.getCell(singleCell._value.model.address).fill = {
              type: 'pattern',
              pattern: 'solid',
              bgColor: { argb: 'FF0000' },
              fgColor: { argb: 'cc0000' },
              color: { argb: 'ffffff' },
            }
            worksheet.getCell(singleCell._value.model.address).alignment = { indent: 1, vertical: 'middle', shrinkToFit: true }
          }
          else {
            worksheet.getCell(singleCell._value.model.address).alignment = { indent: 1, vertical: 'middle', shrinkToFit: true }
          }
        }
        else {
          worksheet.getCell(singleCell._value.model.address).alignment = { indent: 1, vertical: 'middle', shrinkToFit: true }
        }
      });
    });

    const buf = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buf]), 'Training-Matrix.xlsx');
  }
}

decorate(TrainingMatrixStore, {
  List_Data: observable,
  HouseList: observable,
  matrixList: observable,
  RoleList: observable,
  getHouseList: action,
  getRoleList: action,
  TrainingMatrixApi: action,
  exportTraingingSheet: action
})