import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../../config/messages";
import LocalGridConfig from "../../../config/LocalGridConfig";
import { isActiveStatus , IsMandatory , IsFrequency } from "../../../config/globalsFunc";


export default class MasterTrainingStore{
	List_data = [];
	total = 1;
	per_page = LocalGridConfig.options.paginationPageSize
	current_page = 1
	agGrid=null

	setupGrid = (params) => {
		this.agGrid = params
	}

	// change page size, default page size is GridConfig.options.paginationPageSize
	setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
		this.per_page = page
		this.agGrid.api.paginationSetPageSize(parseInt(page))
	}

	getList = () => {
		return Axios.post(`/api/manage_training/list`).then(({ data }) => {		
			if (data.data) {				
				data.data.forEach((item, index) =>  {
					return (
						item.is_active_display = item.status === 1 ? isActiveStatus[1] : isActiveStatus[0],
						item.is_mandatory = item.mandatory === 1 ? IsMandatory[1] : IsMandatory[2],
						item.is_frequency=item.frequency === 1 ? IsFrequency[1] : IsFrequency[2]
					)	                   			                    					 
				});
			}
            this.List_data = data.data
			this.total = data.total
			this.current_page = data.current_page			
            })
	}

	onGridChanged = (params) => {
		localStorage.setItem('master_training', JSON.stringify(params.columnApi.getColumnState()))
	}

	EditMasterTraining = (data) => {
		return Axios.post(`/api/manage_training/edit/${data.id}`, data).then(({ data }) => {
			this.getList()			
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}

	ActiveStatus = (id, status) => {		
		Axios.post(`/api/manage_training/status/${id}`, { "status": status ? 0 : 1 }).then(({ data }) => {			
			if (data.status === 200) {
				this.getList()				
				vsmNotify.success({ message: data.message})
			}
		}).catch((err) => { return })
	}

	DeleteMasterTraining = (id) => {
		return Axios.post(`/api/manage_training/delete/${id}`).then(({ data }) => {		
				this.getList()
				vsmNotify.success({ message: 'successfully deleted' })		
		}).catch((err) => { return })
	}

	AddMasterTraining = (name) => {
		return Axios.post(`/api/manage_training/add`, name).then(({ data }) => {
			this.getList()
		}).catch(({ response: { data } }) => {
			var errors = []
			var notify = null
			if (data.errors) {
				Object.keys(data.errors).forEach(name => {
					if (name === 'both') {
						notify = data.errors[name][0]
					} else {
						errors.push({ name, errors: data.errors[name] })
					}
				})
			}
			return Promise.reject({ errors, notify })
		})
	}
}

decorate(MasterTrainingStore, {
	agGrid:observable,
	AddMasterTraining: action,
	EditMasterTraining: action,
	DeleteMasterTraining: action,
	List_data: observable,
	ActiveStatus: action,
	getList: action,
	setPageSize: action,
	setupGrid: action,
	onGridChanged: action
})