import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import useStore from '../../store';
import { Apps_RouterConfig } from '../../config/RouterConfig';
import { useHistory, useLocation } from 'react-router-dom';
import { Layout, Spin } from 'antd';
import AppsSidebarComponent from './AppsSidebarComponent';
import AppsHeaderComponent from './AppsHeaderComponent';
const { Content } = Layout;

const AppsLayoutComponent = observer(({ children }) => {

  const { AppsAuthStore } = useStore()
  const [collapsed, setCollapsed] = useState(window.innerWidth > 991 ? false : true)
  const [setting, setSetting] = useState(null)
  const [internalLoader, setInternalLoader] = useState(true)

  let history = useHistory()
  let location = useLocation()

  useEffect(() => {
    if (!AppsAuthStore.loading) {
      const apps_redirectDefault = Apps_RouterConfig.find(x => x.default)
      const app_redirectAuthDefault = Apps_RouterConfig.find(x => x.authdefault)
      let path = Apps_RouterConfig.find(x => x.path === location.pathname.trimRight('/'))
      if (path && path.apps_auth && AppsAuthStore.prospects_token === null) {
        history.replace(apps_redirectDefault.path)
      } else if (path && !path.apps_auth && AppsAuthStore.prospects_token && !path.errorpage) {
        history.replace(app_redirectAuthDefault.path)
      }
    } else {
      setInternalLoader(true)
    }


    let apps_route = Apps_RouterConfig.find(x => x.path === location.pathname.trimRight('/'))
    if (apps_route && apps_route.setting) {
      setSetting(apps_route.setting)
    } else {
      if (setting === null) { setSetting({ header: true, nav: true }) }
    }

    setTimeout(() => {
      setInternalLoader(false)
    }, 500)
  }, [AppsAuthStore.loading, AppsAuthStore.prospects_token, history, location.pathname, setting])


  // call logout function
  const Logout = () => {
    // doLogout()
    history.replace('/')
  }

  return (AppsAuthStore.loading || internalLoader) ? (
    <div className="fullscreen__spinner">
      <Spin size="large" />
    </div>
  ) : (
    <Layout className="main__layout__wrapper">
      {
        (AppsAuthStore.prospects_token && setting && setting.nav && (
          <AppsSidebarComponent collapsed={collapsed} />
        ))
      }

      <Layout className="site-layout">
        {
          (AppsAuthStore.prospects_token && setting && setting.header && (
            <AppsHeaderComponent logout={Logout} user={AppsAuthStore.prostects_user} collapsed={collapsed} setCollapsed={setCollapsed} />
          ))
        }
        {
          (AppsAuthStore.prospects_token && setting && (setting.header || setting.nav)) ? (
            <Content className="site-layout-background">
              {children}
            </Content>
          ) : (<>{children}</>)
        }
      </Layout>
    </Layout>
  )
})

export default AppsLayoutComponent;