import Axios from "axios";
import { action, decorate, observable } from "mobx";
import { vsmNotify } from "../../config/messages";
import LocalGridConfig from "../../config/LocalGridConfig";
import { isActiveStatus } from "../../config/globalsFunc";

export default class DocumentsStore {
  agGrid = null;
  List_data = [];
  total = 1;
  per_page = LocalGridConfig.options.paginationPageSize
  current_page = 1;
  editValues = null;


  // Setup grid and set column size to autosize
  setupGrid = (params) => {
    this.agGrid = params
  }

  setPageSize = (page = LocalGridConfig.options.paginationPageSize) => {
    this.per_page = page
    this.agGrid.api.paginationSetPageSize(parseInt(page))
  }

  onGridChanged = (params) => {
    localStorage.setItem('house_list_documents', JSON.stringify(params.columnApi.getColumnState()))
  }

  getList = (id) => {
    return Axios.post(`/api/housedocument/edit/list/${id}`).then(({ data }) => {
      if (data.data) {
        data.data.forEach((item, index) => {
          if (item.category) {
            item.name = item.category.category_name
          }
          item.is_active_display = item.status === 1 ? isActiveStatus[1] : isActiveStatus[0]
          item.fullname = item.first_name + " " + item.last_name
        });
      }
      this.List_data = data.data
      this.total = data.total
      this.current_page = data.current_page
    })
  }

  AddHouseDoc = (id, e) => {
    return Axios.post(`/api/housedocument/edit/add/${id}`, e).then(({ data }) => {
      this.getList(this.editValues.id)
    }).catch(({ response: { data } }) => {
      var errors = []
      var notify = null
      if (data.errors) {
        Object.keys(data.errors).forEach(name => {
          if (name === 'both') {
            notify = data.errors[name][0]
          } else {
            errors.push({ name, errors: data.errors[name] })
          }
        })
      }
      return Promise.reject({ errors, notify })
    })
  }

  setEditValues = (data) => {
    this.editValues = data;
    return this.editValues;
  }

  //Edit Data
  EditHouseDoc = (id, formdata, parent_id) => {
    return Axios.post(`/api/housedocument/edit/edit/${parent_id}/${id}`, formdata)
      .then(({ data }) => {
        this.getList(parent_id)
        return data;
      })
      .catch(({ response: { data } }) => {
        var errors = [];
        Object.keys(data.errors).forEach((name) => {
          errors.push({ name, errors: data.errors[name] });
        });
        data.errors = errors;
        return Promise.reject(data);
      });
  };

  DeleteHouseDoc = (id, parent_id) => {
    return Axios.post(`/api/housedocument/edit/delete/${parent_id}/${id}`).then(({ data }) => {
    this.getList(parent_id)
    return data
    }).catch(({ response: { data } }) => {
    // vsmNotify.error({ message: data })
    var errors = [];
    Object.keys(data.errors).forEach((name) => {
    errors.push({ name, errors: data.errors[name] });
    });
    data.errors = errors;
    return Promise.reject(data);
    })
    
    }

  JsonToFormData = (data) => {
    const formData = new FormData();
    Object.keys(data).forEach(key => {
      if (![document].includes(key)) {
        formData.append(key, (data[key] !== undefined && data[key] !== null) ? data[key] : '')
      }
    })
    return formData
  }

  ActiveStatus = (id, status) => {
    Axios.post(`/api/house/status/${id}`, { "status": status ? 0 : 1 }).then(({ data }) => {
      if (data.status === 200) {
        this.getList(this.editValues.id)
        vsmNotify.success({ message: data.message })
      }
    }).catch((err) => { return })
  }
}

decorate(DocumentsStore, {
  List_data: observable,
  agGrid: observable,
  total: observable,
  current_page: observable,
  per_page: observable,
  editValues: observable,

  getList: action,
  setPageSize: action,
  setupGrid: action,
  createDatasource: action,
  AddHouseDoc: action,
  EditHouseDoc: action,
  DeleteHouseDoc: action,
  ActiveStatus: action,
  setEditValues: action,
  getDocList: action,
  onGridChanged: action,
  JsonToFormData: action
})